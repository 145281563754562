import {httpRequest} from "./http-request";
import {stringsHelper} from "../helpers/strings.helper";

export const companyService = {
  getEmployeeList,
  createEmployee,
  getSmsVerification,
  sendCodes,
  changeEmployeeName,
  blockEmployee,
  unblockEmployee,
  archiveEmployee,
  getEntitiesList,
  getOrganisationFormsList,
  createLegalEntity,
  createLithuaniaLegalEntity,
  getShipperCompaniesList,
  getOrderInfo,
  createOrder: createOrderToShipperCompany,
  getOrdersToShipperCompanyList,
  resendOrder,
  getOrdersList: getOrdersToConsignorList,
  downloadLegalEntityDocs,
  getLegalEntityData,
  getLegalEntityDocs,
  editLegalEntityData,
  editLegalEntityDocs,
  uploadContractsBlank,
  uploadFilledOrganizationContractBlank,
  createOrderToConsignor,
  createLegalEntityValidateStepData,
  deleteLegalEntity,
  getOrdersForConsignorList,
  getLegalEntityFullInfo,
  getEntitiesListAll,
  archiveLegalEntity,
  unarchiveLegalEntity,
  getCompanyInfo,
  createForeignLegalEntityValidateStepData,
  editForeignLegalEntity,
  getCompanyId,
  getCompanyDrivers,
  getCompanyTrucks,
  getCompanyTrails,
  getCompanyDriversQuestionnaire,
  getCompanyTrucksQuestionnaire,
  getCompanyTrailsQuestionnaire,
  deleteCompany,
  createBelarusLegalEntityValidateStepData,
  createBelarusLegalEntity,
  editBelarusLegalEntity,
  getGetLegalEntityDocsList,
  getList,
  getListForConsignor,
  getCompanySettingsByConsignor,
  saveCompanySettingsByConsignor,
  editCompanyByConsignor,
  editLegalEntityValidateStepData,
  getShipperCompanyList,
  getNewOrdersCount,
  getEmployeeListForConsignor,
  getRefuseReasonInfo,
  getApproveOrderHistory,
  changeTypeByOrderId,
  requestAllowInternational
};

async function getEmployeeList() {
  return await httpRequest.get(`company/get-employee-list`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getEntitiesList() {
  return await httpRequest.get(`company/get-legal-entities-list`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getEntitiesListAll() {
  return await httpRequest.get(`company/get-legal-entities-list-all`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getOrganisationFormsList(countryId = null) {
  let url = `company/get-organisation-forms-list`;
  if (countryId) {
    url += `?countryId=` + countryId;
  }

  return await httpRequest.get(url).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getSmsVerification({employeeId, newPhone}) {
  const formData = new FormData();
  formData.append("employeeId", employeeId);
  formData.append("newPhone", newPhone);

  return await httpRequest
    .post("company/get-sms-verification-on-editing-employee-phone", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function sendCodes({employeeId, oldPhoneToken, oldPhoneCode, newPhoneToken, newPhoneCode, newPhone}) {
  const formData = new FormData();
  formData.append("employeeId", employeeId);
  formData.append("oldPhoneToken", oldPhoneToken);
  formData.append("oldPhoneCode", oldPhoneCode);
  formData.append("newPhoneToken", newPhoneToken);
  formData.append("newPhoneCode", newPhoneCode);
  formData.append("newPhone", newPhone);

  return await httpRequest
    .post("company/edit-employee-phone", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function changeEmployeeName({employeeId, firstName, secondName}) {
  const formData = new FormData();
  formData.append("employeeId", employeeId);
  formData.append("firstName", firstName);
  formData.append("secondName", secondName);

  return await httpRequest
    .post("company/edit-employee-main-params", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function deleteLegalEntity(entityId) {
  const formData = new FormData();
  formData.append("legalEntityId", entityId);

  return await httpRequest
    .post("company/delete-legal-entity", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function blockEmployee({employeeId}) {
  const formData = new FormData();
  formData.append("employeeId", employeeId);

  return await httpRequest
    .post("company/block-employee", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function unblockEmployee({employeeId}) {
  const formData = new FormData();
  formData.append("employeeId", employeeId);

  return await httpRequest
    .post("company/unblock-employee", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function archiveEmployee({employeeId}) {
  const formData = new FormData();
  formData.append("employeeId", employeeId);

  return await httpRequest
    .post("company/archive-employee", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createEmployee({phone, email, firstName, secondName, patronymic, password, passwordRepeat}) {
  const formData = new FormData();

  formData.append("firstName", firstName);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("secondName", secondName);
  formData.append("patronymic", patronymic);
  formData.append("password", password);
  formData.append("passwordRepeat", passwordRepeat);

  return await httpRequest
    .post("company/create-employee", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createLegalEntityValidateStepData(data, step, orgFromId, country, legalEntityId, edit = false, isProprietor = true) {
  const formData = new FormData();

  formData.append('edit', edit);

  formData.append('legalEntityId', legalEntityId);

  formData.append('name', data.name);
  formData.append('stepNumber', step);
  formData.append('orgFormId', orgFromId);
  formData.append('country', country);

  formData.append('dateRegistration', data.dateRegistration ? data.dateRegistration.format('YYYY-MM-DD') : data.dateRegistration);
  formData.append('legalReason', isProprietor === true ? 'Свидетельство о регистрации ИП' : data.legalReason);
  formData.append('firstName', data.firstName);
  formData.append('secondName', data.secondName);
  formData.append('patronymic', data.patronymic);
  formData.append('inn', data.inn);
  formData.append('bic', data.bic);
  formData.append('bankCorS', data.bankCorS);
  formData.append('bankName', data.bankName);
  formData.append('bic', data.bic);
  formData.append('ogrnip', data.ogrnip);
  formData.append('ogrn', data.ogrn);
  formData.append('okpo', data.okpo);
  formData.append('rs', data.rs);
  formData.append('position', data.position);
  formData.append('kpp', data.kpp);
  formData.append('workWithNds', data.workWithNds);
  formData.append('taxType', data.taxType);
  formData.append('taxPercent', data.taxPercent);
  formData.append('archiveByInn', data.archiveByInn === true ? 1 : 0);

  formData.append('legalRegion', data.legalRegion);
  formData.append('legalLocality', data.legalLocality);
  formData.append('legalStreet', data.legalStreet);
  formData.append('legalHouseNumber', data.legalHouseNumber);
  formData.append('legalPremiseNumber', data.legalPremiseNumber);
  formData.append('legalAddressPostalCode', data.legalAddressPostalCode);

  if (data.regAddressCheckBox) {
    formData.append('regRegion', data.legalRegion);
    formData.append('regLocality', data.legalLocality);
    formData.append('regStreet', data.legalStreet);
    formData.append('regHouseNumber', data.legalHouseNumber);
    formData.append('regPremiseNumber', data.legalPremiseNumber);
    formData.append('regAddressPostalCode', data.legalAddressPostalCode);
  } else {
    formData.append('regRegion', data.regRegion);
    formData.append('regLocality', data.regLocality);
    formData.append('regStreet', data.regStreet);
    formData.append('regHouseNumber', data.regHouseNumber);
    formData.append('regPremiseNumber', data.regPremiseNumber);
    formData.append('regAddressPostalCode', data.regAddressPostalCode);
  }

  if (data.factAddressCheckBox) {
    formData.append('mailingRegion', formData.get('regRegion'));
    formData.append('mailingLocality', formData.get('regLocality'));
    formData.append('mailingStreet', formData.get('regStreet'));
    formData.append('mailingHouseNumber', formData.get('regHouseNumber'));
    formData.append('mailingPremiseNumber', formData.get('regPremiseNumber'));
    formData.append('mailingAddressPostalCode', formData.get('regAddressPostalCode'));
  } else {
    formData.append('mailingRegion', data.mailingRegion);
    formData.append('mailingLocality', data.mailingLocality);
    formData.append('mailingStreet', data.mailingStreet);
    formData.append('mailingHouseNumber', data.mailingHouseNumber);
    formData.append('mailingPremiseNumber', data.mailingPremiseNumber);
    formData.append('mailingAddressPostalCode', data.mailingAddressPostalCode);
  }

  formData.append('atiCode', data.atiCode);
  formData.append('guarantorFirstName', data.guarantorFirstName);
  formData.append('guarantorSecondName', data.guarantorSecondName);
  formData.append('guarantorPatronymic', data.guarantorPatronymic);
  formData.append('guarantorPassportSerial', data.guarantorPassportSerial);
  formData.append('guarantorPassportNumber', data.guarantorPassportNumber);
  formData.append('guarantorPassportDivision', data.guarantorPassportDivision);
  formData.append('guarantorPassportDate', data.guarantorPassportDate ? data.guarantorPassportDate.format('DD.MM.YYYY') : data.guarantorPassportDate);
  formData.append('guarantorPassportRegistration', data.guarantorPassportRegistration);

  if (data.guarantorFactAddressCheckBox) {
    formData.append('guarantorFactRegion', data.guarantorRegRegion);
    formData.append('guarantorFactLocality', data.guarantorRegLocality);
    formData.append('guarantorFactStreet', data.guarantorRegStreet);
    formData.append('guarantorFactHouseNumber', data.guarantorRegHouseNumber);
    formData.append('guarantorFactPremiseNumber', data.guarantorRegPremiseNumber);
    formData.append('guarantorFactAddressPostalCode', data.guarantorRegAddressPostalCode);
  } else {
    formData.append('guarantorFactRegion', data.guarantorFactRegion);
    formData.append('guarantorFactLocality', data.guarantorFactLocality);
    formData.append('guarantorFactStreet', data.guarantorFactStreet);
    formData.append('guarantorFactHouseNumber', data.guarantorFactHouseNumber);
    formData.append('guarantorFactPremiseNumber', data.guarantorFactPremiseNumber);
    formData.append('guarantorFactAddressPostalCode', data.guarantorFactAddressPostalCode);
  }

  formData.append('guarantorRegRegion', data.guarantorRegRegion);
  formData.append('guarantorRegLocality', data.guarantorRegLocality);
  formData.append('guarantorRegStreet', data.guarantorRegStreet);
  formData.append('guarantorRegHouseNumber', data.guarantorRegHouseNumber);
  formData.append('guarantorRegPremiseNumber', data.guarantorRegPremiseNumber);
  formData.append('guarantorRegAddressPostalCode', data.guarantorRegAddressPostalCode);

  return await httpRequest
    .post("company/validate-legal-entity", formData, {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createLegalEntity(data, files, isProprietor, purposeType) {
  const formData = new FormData();

  formData.append('name', data.name);
  formData.append('orgFormId', data.orgFormId);

  formData.append('dateRegistration', data.dateRegistration ? data.dateRegistration.format('YYYY-MM-DD') : data.dateRegistration);
  formData.append('legalReason', isProprietor ? 'Свидетельство о регистрации ИП' : data.legalReason);
  formData.append('firstName', data.firstName);
  formData.append('secondName', data.secondName);
  formData.append('patronymic', data.patronymic);
  formData.append('inn', data.inn);
  formData.append('bic', data.bic);
  formData.append('bankCorS', data.bankCorS);
  formData.append('bankName', data.bankName);
  formData.append('bic', data.bic);
  formData.append('ogrnip', data.ogrnip);
  formData.append('ogrn', data.ogrn);
  formData.append('okpo', data.okpo);
  formData.append('rs', data.rs);
  formData.append('position', data.position);
  formData.append('kpp', data.kpp);
  formData.append('workWithNds', data.workWithNds);
  formData.append('taxType', data.taxType);
  formData.append('taxPercent', data.taxPercent);
  formData.append('archiveByInn', data.archiveByInn === true ? 1 : 0)

  formData.append('country', data.country);

  formData.append('legalRegion', data.legalRegion);
  formData.append('legalLocality', data.legalLocality);
  formData.append('legalStreet', data.legalStreet ? data.legalStreet : '');
  formData.append('legalHouseNumber', data.legalHouseNumber);
  formData.append('legalPremiseNumber', data.legalPremiseNumber);
  formData.append('legalAddressPostalCode', data.legalAddressPostalCode);

  if (data.regAddressCheckBox) {
    formData.append('regRegion', data.legalRegion);
    formData.append('regLocality', data.legalLocality);
    formData.append('regStreet', data.legalStreet ? data.legalStreet : '');
    formData.append('regHouseNumber', data.legalHouseNumber);
    formData.append('regPremiseNumber', data.legalPremiseNumber);
    formData.append('regAddressPostalCode', data.legalAddressPostalCode);
  } else {
    formData.append('regRegion', data.regRegion);
    formData.append('regLocality', data.regLocality);
    formData.append('regStreet', data.regStreet ? data.regStreet : '');
    formData.append('regHouseNumber', data.regHouseNumber);
    formData.append('regPremiseNumber', data.regPremiseNumber);
    formData.append('regAddressPostalCode', data.regAddressPostalCode);
  }

  if (data.factAddressCheckBox) {
    formData.append('mailingRegion', formData.get('regRegion'));
    formData.append('mailingLocality', formData.get('regLocality'));
    formData.append('mailingStreet', formData.get('regStreet') ? formData.get('regStreet') : '');
    formData.append('mailingHouseNumber', formData.get('regHouseNumber'));
    formData.append('mailingPremiseNumber', formData.get('regPremiseNumber'));
    formData.append('mailingAddressPostalCode', formData.get('regAddressPostalCode'));
  } else {
    formData.append('mailingRegion', data.mailingRegion);
    formData.append('mailingLocality', data.mailingLocality);
    formData.append('mailingStreet', data.mailingStreet ? data.mailingStreet : '');
    formData.append('mailingHouseNumber', data.mailingHouseNumber);
    formData.append('mailingPremiseNumber', data.mailingPremiseNumber);
    formData.append('mailingAddressPostalCode', data.mailingAddressPostalCode);
  }

  formData.append('atiCode', data.atiCode);
  formData.append('guarantorFirstName', data.guarantorFirstName);
  formData.append('guarantorSecondName', data.guarantorSecondName);
  formData.append('guarantorPatronymic', data.guarantorPatronymic);
  formData.append('guarantorPassportSerial', data.guarantorPassportSerial);
  formData.append('guarantorPassportNumber', data.guarantorPassportNumber);
  formData.append('guarantorPassportDivision', data.guarantorPassportDivision);
  formData.append('guarantorPassportDate', data.guarantorPassportDate ? data.guarantorPassportDate.format('DD.MM.YYYY') : data.guarantorPassportDate);

  if (data.guarantorFactAddressCheckBox) {
    formData.append('guarantorFactRegion', data.guarantorRegRegion);
    formData.append('guarantorFactLocality', data.guarantorRegLocality);
    formData.append('guarantorFactStreet', data.guarantorRegStreet ? data.guarantorRegStreet : '');
    formData.append('guarantorFactHouseNumber', data.guarantorRegHouseNumber);
    formData.append('guarantorFactPremiseNumber', data.guarantorRegPremiseNumber);
    formData.append('guarantorFactAddressPostalCode', data.guarantorRegAddressPostalCode);
  } else {
    formData.append('guarantorFactRegion', data.guarantorFactRegion);
    formData.append('guarantorFactLocality', data.guarantorFactLocality);
    formData.append('guarantorFactStreet', data.guarantorFactStreet ? data.guarantorFactStreet : '');
    formData.append('guarantorFactHouseNumber', data.guarantorFactHouseNumber);
    formData.append('guarantorFactPremiseNumber', data.guarantorFactPremiseNumber);
    formData.append('guarantorFactAddressPostalCode', data.guarantorFactAddressPostalCode);
  }

  formData.append('guarantorRegRegion', data.guarantorRegRegion);
  formData.append('guarantorRegLocality', data.guarantorRegLocality);
  formData.append('guarantorRegStreet', data.guarantorRegStreet ? data.guarantorRegStreet : '');
  formData.append('guarantorRegHouseNumber', data.guarantorRegHouseNumber);
  formData.append('guarantorRegPremiseNumber', data.guarantorRegPremiseNumber);
  formData.append('guarantorRegAddressPostalCode', data.guarantorRegAddressPostalCode);
  formData.append('guarantorPassportRegistration', data.guarantorPassportRegistration);

  formData.append('isNeedPowerOfAttorneyForAPerson', ((data.legalReason === 'Доверенность (ООО)') ? '1' : '0'));
  formData.append('purposeType', purposeType);

  for (const [key, value] of Object.entries(files)) {
    if (Array.isArray(value)) {
      let count = 0;
      for (const file of value) {
        let fileName = (count > 0) ? key + count : key;

        formData.append(fileName, file);
        count++;
      }
    } else {
      formData.append(key, value);
    }
  }

  return await httpRequest
    // .post("company/create-legal-entity", formData, {
    .post("legal-entity/create-rus-legal-entity", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function editLegalEntityValidateStepData(data, step, orgFromId, legalEntityId) {
  const formData = new FormData();

  formData.append('legalEntityId', legalEntityId);
  formData.append('stepNumber', step);
  formData.append('orgFormId', orgFromId);

  for (const key in data) {
    formData.append(key, data[key]);
  }

  formData.set('workWithNds', formData.get('workWithNds') == 1 ? 1 : 0);
  formData.set('dateRegistration', data.dateRegistration ? data.dateRegistration.format('YYYY-MM-DD') : data.dateRegistration);

  return await httpRequest
    .post("company/validate-legal-entity-step-on-editing", formData, {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createForeignLegalEntityValidateStepData(data, step, orgFormId, country, edit = false) {
  const formData = new FormData();

  formData.append('edit', edit);

  formData.append('stepNumber', step);

  formData.append('countryId', country);

  formData.append('name', data.name);
  formData.append('fullName', data.fullName);
  formData.append('firstName', data.firstName);
  formData.append('secondName', data.secondName);
  formData.append('patronymic', data.patronymic);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('bankName', data.bankName);
  formData.append('accountNumber', data.accountNumber);
  formData.append('swift', data.swift);
  formData.append('position', data.position);
  formData.append('registerNumber', data.registerNumber);
  formData.append('legalNumber', data.legalNumber);
  formData.append('legalReason', data.legalReason);
  formData.append('address', data.address);
  formData.append('mailingAddress', data.mailingAddress);

  formData.append('guarantorFirstName', data.guarantorFirstName);
  formData.append('guarantorSecondName', data.guarantorSecondName);
  formData.append('guarantorPatronymic', data.guarantorPatronymic);
  formData.append('guarantorPassportSerial', data.guarantorPassportSerial);
  formData.append('guarantorPassportNumber', data.guarantorPassportNumber);
  formData.append('guarantorAddressFact', data.guarantorAddressFact);
  formData.append('guarantorCountry', data.guarantorCountry);
  formData.append('guarantorPhone', data.guarantorPhone);
  formData.append('guarantorEmail', data.guarantorEmail);

  return await httpRequest
    .post("company/validate-foreign-legal-entity", formData, {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createLithuaniaLegalEntity(data, files) {
  const formData = new FormData();

  formData.append('orgFormId', data.orgFormId);
  formData.append('countryId', data.country);
  formData.append('name', data.name);
  formData.append('fullName', data.fullName);
  formData.append('firstName', data.firstName);
  formData.append('secondName', data.secondName);
  formData.append('patronymic', data.patronymic);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('bankName', data.bankName);
  formData.append('accountNumber', data.accountNumber);
  formData.append('swift', data.swift);
  formData.append('position', data.position);
  formData.append('registerNumber', data.registerNumber);
  formData.append('legalNumber', data.legalNumber);
  formData.append('address', data.address);
  formData.append('mailingAddress', data.mailingAddress);
  formData.append('legalReason', data.legalReason);

  formData.append('guarantorFirstName', data.guarantorFirstName);
  formData.append('guarantorSecondName', data.guarantorSecondName);
  formData.append('guarantorPatronymic', data.guarantorPatronymic);
  formData.append('guarantorPassportSerial', data.guarantorPassportSerial);
  formData.append('guarantorPassportNumber', data.guarantorPassportNumber);
  formData.append('guarantorAddressFact', data.guarantorAddressFact);
  formData.append('guarantorCountry', data.guarantorCountry);
  formData.append('guarantorPhone', data.guarantorPhone);
  formData.append('guarantorEmail', data.guarantorEmail);

  for (const [key, value] of Object.entries(files)) {
    if (Array.isArray(value)) {
      let count = 0;
      for (const file of value) {
        let fileName = (count > 0) ? key + count : key;

        formData.append(fileName, file);
        count++;
      }
    } else {
      formData.append(key, value);
    }
  }

  return await httpRequest
    .post("company/create-foreign-legal-entity", formData, {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getShipperCompaniesList(legalEntityId) {
  const formData = new FormData();
  formData.append('legalEntityId', legalEntityId);
  return await httpRequest.post('shipper-company/get-list-with-orders', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getOrderInfo(orderId) {
  const formData = new FormData();
  formData.append('orderId', orderId);
  return await httpRequest.post('approve-order/get-info', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function createOrderToShipperCompany(legalEntityId, shipperCompanyId, distributionCenterId, currencyTypeId, addParams, addFiles, contractType) {
  const formData = new FormData();
  formData.append('LegalEntityId', legalEntityId);
  formData.append('ShipperCompanyId', shipperCompanyId);
  formData.append('DistributionCenterId', distributionCenterId);
  formData.append('CurrencyTypeId', currencyTypeId);
  formData.append('ContractType', contractType);

  if (addParams) {
    addParams.forEach(value => {
      formData.append(stringsHelper.ucFirst(value.Name), value.Value ? value.Value : '');
    });
  }

  if (addFiles) {
    addFiles.forEach(value => {
      formData.append(stringsHelper.ucFirst(value.Name), value.Value ? value.Value : '');
    });
  }

  return await httpRequest.post('approve-order/create-order-to-shipper-company', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function createOrderToConsignor(legalEntityId, consignorId, distCenterId) {
  const formData = new FormData();

  formData.append('LegalEntityId', legalEntityId);
  formData.append('ConsignorId', consignorId);
  formData.append('DistributionCenterId', distCenterId);

  return await httpRequest.post('approve-order/create', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function resendOrder(orderId) {
  const formData = new FormData();
  formData.append('orderId', orderId);
  return await httpRequest.post('approve-order/resend-order', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function downloadLegalEntityDocs(legalEntityId) {
  const formData = new FormData();
  formData.append('legalEntityId', legalEntityId);
  return await httpRequest.post('company/download-legal-entity-docs', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getLegalEntityData(legalEntityId) {
  const formData = new FormData();
  formData.append('LegalEntityId', legalEntityId);
  return await httpRequest.post('company/get-legal-entity-main-data', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getLegalEntityDocs(legalEntityId) {
  const formData = new FormData();
  formData.append('LegalEntityId', legalEntityId);
  return await httpRequest.post('company/get-legal-entity-docs', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function editLegalEntityData(legalEntityData, legalEntityId) {
  const formData = new FormData();

  formData.append('legalEntityId', legalEntityId);

  for (const key in legalEntityData) {
    formData.append(key, legalEntityData[key]);
  }

  formData.set('workWithNds', formData.get('workWithNds') == 1 ? 1 : 0);
  formData.set('dateRegistration', legalEntityData.dateRegistration ? legalEntityData.dateRegistration.format('YYYY-MM-DD') : legalEntityData.dateRegistration);

  return await httpRequest
    // .post('company/edit-legal-entity-main-data', formData)
    .post('legal-entity/edit-rus-legal-entity', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function editForeignLegalEntity(legalEntityData, legalEntityId) {
  const formData = new FormData();

  formData.append('legalEntityId', legalEntityId);

  for (const key in legalEntityData) {
    formData.append(key, legalEntityData[key]);
  }

  return await httpRequest.post('company/edit-foreign-legal-entity', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function editLegalEntityDocs(legalEntityDocs, orgFormId, legalEntityId, countryId, isNeedPowerOfAttorneyForAPerson, isNdsPayer) {
  const formData = new FormData();

  formData.append('orgFormId', orgFormId);
  formData.append('legalEntityId', legalEntityId);
  formData.append('countryId', countryId);

  formData.append('isNeedPowerOfAttorneyForAPerson', isNeedPowerOfAttorneyForAPerson);
  formData.append('isNdsPayer', isNdsPayer);

  for (const [key, value] of Object.entries(legalEntityDocs)) {
    if (Array.isArray(value)) {
      let count = 1;
      for (const file of value) {
        if (count > 1) {
          formData.append(key + count, file);
        } else {
          formData.append(key, file);
        }
        count++;
      }
    } else {
      formData.append(key, value[0]);
    }
  }

  return await httpRequest.post('company/edit-legal-entity-docs', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function uploadFilledOrganizationContractBlank(orderId, filledContractBlank) {
  const formData = new FormData();
  formData.append('OrderId', orderId);
  formData.append('OrganizationContract', filledContractBlank);
  return await httpRequest.post('approve-order/upload-organization-contract-order-to-shipper-company', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function uploadContractsBlank(orderId, guarantorContract, organizationContract) {
  const formData = new FormData();
  formData.append('OrderId', orderId);
  formData.append('GuarantorContract', guarantorContract);
  formData.append('OrganizationContract', organizationContract);

  return await httpRequest.post('approve-order/upload-filled-contracts-order-to-shipper-company', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });

}

async function getOrdersToConsignorList() {
  return await httpRequest.get('approve-order/get-list-for-company').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getOrdersToShipperCompanyList() {
  return await httpRequest.get('approve-order/get-list-orders-to-shipper-companies').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getOrdersForConsignorList(status, legalEntityName) {
  return await httpRequest.get(`approve-order/get-list-for-consignor?orderStatusId=${status}&legalEntityName=${legalEntityName}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getLegalEntityFullInfo(legalEntityId) {
  const formData = new FormData();
  formData.append('LegalEntityId', legalEntityId);

  return await httpRequest.post('company/get-legal-entity-main-data', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function archiveLegalEntity(legalEntityId) {
  const formData = new FormData();
  formData.append("Id", legalEntityId);

  return await httpRequest
    .post("company/archive-legal-entity", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function unarchiveLegalEntity(legalEntityId) {
  const formData = new FormData();
  formData.append("Id", legalEntityId);

  return await httpRequest
    .post("company/unarchive-legal-entity", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCompanyInfo(companyID = null) {
  const addParams = companyID ? `?companyID=${companyID}` : '';

  return await httpRequest.get('company/get-company-info' + addParams).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getCompanyId() {
  return await httpRequest.get('company/get-company-id').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getCompanyDrivers() {
  return await httpRequest.get('company/get-company-drivers').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getCompanyTrucks() {
  return await httpRequest.get('company/get-company-trucks').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getCompanyTrails() {
  return await httpRequest.get('company/get-company-trails').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getCompanyDriversQuestionnaire(driverId) {
  const formData = new FormData();
  formData.append('driverId', driverId);
  return await httpRequest.post('company/get-company-drivers-questionnaire', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCompanyTrucksQuestionnaire(truckId) {
  const formData = new FormData();
  formData.append('truckId', truckId);
  return await httpRequest.post('company/get-company-trucks-questionnaire', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCompanyTrailsQuestionnaire(trailId) {
  const formData = new FormData();
  formData.append('trailId', trailId);
  return await httpRequest.post('company/get-company-trails-questionnaire', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function deleteCompany(id, type) {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('type', type);
  return await httpRequest.post('company/delete-company', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getShipperCompanyList() {
  return await httpRequest.get('shipper-company/get-list').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getRefuseReasonInfo(id) {
  const formData = new FormData();
  formData.append('orderId', id);
  return await httpRequest.post('approve-order/get-info', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getContactsList(id) {
  const formData = new FormData();
  formData.append('orderId', id);
  return await httpRequest.post('approve-order/get-info', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getNewOrdersCount() {
  return await httpRequest.get('approve-order/get-new-orders-count').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getEmployeeListForConsignor(id) {
  return await httpRequest.get(`company/get-employee-list?id=${id}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getApproveOrderHistory(id) {
  return await httpRequest.get(`approve-order/get-approve-order-history?id=${id}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getListForConsignor(filterParams = null) {

  let url = `legal-entity/get-list-for-consignor?limit=${filterParams.limit}&offset=${filterParams.offset}`;

  if (filterParams.consignorID !== null) {
    url += `&consignorID=${filterParams.consignorID}`
  }

  if (filterParams.haveAccreditation === true) {
    url += `&haveAccreditation=true`
  }

  if (filterParams.myAccess === true) {
    url += `&myAccess=true`
  }

  if (filterParams.haveDelivDelay === true) {
    url += `&haveDelivDelay=true`
  }

  if (filterParams.name !== null) {
    url += `&name=${filterParams.name}`
  }

  if (filterParams.minDelivDelay !== null) {
    url += `&minDelivDelay=${filterParams.minDelivDelay}`
  }

  if (filterParams.maxDelivDelay !== null) {
    url += `&maxDelivDelay=${filterParams.maxDelivDelay}`
  }

  if (filterParams.legalNumber !== null) {
    url += `&legalNumber=${filterParams.legalNumber}`
  }

  if (filterParams.webid !== null) {
    url += `&webid=${filterParams.webid}`
  }

  if (filterParams.username !== null) {
    url += `&username=${filterParams.username}`
  }

  if (filterParams.agreedContractByShipperCompanies && filterParams.agreedContractByShipperCompanies.length) {
    let str = '';
    filterParams.agreedContractByShipperCompanies.map((item, index) => {
      str += `&agreedContractByShipperCompanies[${index}]=${item}`;
    })
    url += str;
  }

  if (filterParams.contractApproveStatuses && filterParams.contractApproveStatuses.length) {
    let str = '';
    filterParams.contractApproveStatuses.map((item, index) => {
      str += `&contractApproveStatuses[${index}]=${item}`;
    })
    url += str;
  }

  if (filterParams.legalEntityApproveStatuses && filterParams.legalEntityApproveStatuses.length) {
    let str = '';
    filterParams.legalEntityApproveStatuses.map((item, index) => {
      str += `&legalEntityApproveStatuses[${index}]=${item}`;
    })
    url += str;
  }

  if (filterParams.companyTypes && filterParams.companyTypes.length) {
    let str = '';
    filterParams.companyTypes.map((item, index) => {
      str += `&companyTypes[${index}]=${item}`;
    })
    url += str;
  }

  if (filterParams.distributionCenterId && filterParams.distributionCenterId.length) {
    let str = '';
    filterParams.distributionCenterId.map((item, index) => {
      str += `&distributionCenterId[${index}]=${item}`;
    })
    url += str;
  }

  return await httpRequest.get(url).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getList(forConsignor = true, filterParams = null) {
  let url = `company/get-list?forConsignor=${forConsignor}`;
  if (filterParams !== null) {
    if (filterParams.onlyWithAccreditation === true) {
      url += `&haveAccreditation=true`
    }

    if (filterParams.onlyWithDelivDelay === true) {
      url += `&haveDelivDelay=true`
    }

    if (filterParams.minDelivDelay !== null) {
      url += `&minDelivDelay=${filterParams.minDelivDelay}`
    }

    if (filterParams.maxDelivDelay !== null) {
      url += `&maxDelivDelay=${filterParams.maxDelivDelay}`
    }

    if (filterParams.legalNumber !== null) {
      url += `&legalNumber=${filterParams.legalNumber}`
    }

    if (filterParams.username !== null) {
      url += `&username=${filterParams.username}`
    }
  }

  return await httpRequest.get(url).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getCompanySettingsByConsignor(companyID) {
  return await httpRequest.get(`company/get-settings-by-consignor?companyID=${companyID}`).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function saveCompanySettingsByConsignor(settingsData) {
  const formData = new FormData();
  formData.append('companyID', settingsData.companyID);
  formData.append('accreditationInternationalDeliveries', settingsData.accreditationInternationalDeliveries);
  formData.append('delayNewDeliveries', settingsData.newDeliveryDelay);
  formData.append('isCanSwitchToConsignor', settingsData.isCanSwitchToConsignor);

  return await httpRequest.post('company/save-settings-by-consignor', formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function editCompanyByConsignor(companyID, data) {
  const formData = new FormData();
  formData.append('mainUserPhone', data.MainUserPhone);

  return await httpRequest.post(`company/edit-company-by-consignor?companyID=${companyID}`, formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

//Беларусь
async function createBelarusLegalEntityValidateStepData(data, step, orgFormId, country, edit = false) {
  const formData = new FormData();

  formData.append('Edit', edit);

  formData.append('StepNumber', step);

  formData.append('CountryId', country);
  formData.append('OrgFormId', orgFormId);

  formData.append('Name', data.name);
  formData.append('FullName', data.fullName);
  formData.append('WorkWithNds', data.workWithNds);
  formData.append('FirstName', data.firstName);
  formData.append('SecondName', data.secondName);
  formData.append('Patronymic', data.patronymic);
  formData.append('Email', data.email);
  formData.append('Phone', data.phone);
  formData.append('BankName', data.bankName);
  formData.append('BankAddress', data.bankAddress);
  formData.append('AccountNumber', data.accountNumber);
  formData.append('Swift', data.swift);
  formData.append('BankUnp', data.bankUnp);
  formData.append('Bic', data.bic);
  formData.append('correspondentBankName', data.correspondentBankName);
  formData.append('correspondentBankInn', data.correspondentBankInn);
  formData.append('correspondentBankBic', data.correspondentBankBic);
  formData.append('correspondentBankLegalAddress', data.correspondentBankLegalAddress);
  formData.append('correspondentBankAccount', data.correspondentBankAccount);
  formData.append('correspondentBankCheckingAccount', data.correspondentBankCheckingAccount);
  formData.append('Position', data.position);
  formData.append('LegalNumber', data.legalNumber);
  formData.append('LegalReason', orgFormId === 16 ? 'Свидетельство о регистрации ИП' : data.legalReason);
  formData.append('AtiCode', data.atiCode);

  formData.append('RegRegion', data.regRegion);
  formData.append('RegLocality', data.regLocality);
  formData.append('RegStreet', data.regStreet);
  formData.append('RegHouseNumber', data.regHouseNumber);
  formData.append('RegPremiseNumber', data.regPremiseNumber);
  formData.append('RegPostalCode', data.regPostalCode);
  formData.append('MailingRegion', data.mailingRegion);
  formData.append('MailingLocality', data.mailingLocality);
  formData.append('MailingStreet', data.mailingStreet);
  formData.append('MailingHouseNumber', data.mailingHouseNumber);
  formData.append('MailingPremiseNumber', data.mailingPremiseNumber);
  formData.append('MailingPostalCode', data.MailingPostalCode);

  formData.append('GuarantorFirstName', data.guarantorFirstName);
  formData.append('GuarantorSecondName', data.guarantorSecondName);
  formData.append('GuarantorPatronymic', data.guarantorPatronymic);
  formData.append('GuarantorPassportSerial', data.guarantorPassportSerial);
  formData.append('GuarantorPassportNumber', data.guarantorPassportNumber);
  formData.append('GuarantorPassportDate', data.guarantorPassportDate ? data.guarantorPassportDate.format('DD.MM.YYYY') : data.guarantorPassportDate);
  formData.append('GuarantorPassportRegistration', data.guarantorPassportRegistration);
  formData.append('GuarantorAddressFact', data.guarantorAddressFact);
  formData.append('GuarantorAddressRegistration', data.guarantorAddressRegistration);
  formData.append('GuarantorCountry', data.guarantorCountry);
  formData.append('GuarantorPhone', data.guarantorPhone);
  formData.append('GuarantorEmail', data.guarantorEmail);

  //Редактирование
  formData.append('AddressCity', data.addressCity);
  formData.append('Address', data.address);
  formData.append('MailingAddress', data.mailingAddress);

  return await httpRequest
    .post("company/validate-belarus-legal-entity", formData, {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createBelarusLegalEntity(data, files, country, orgFormId) {
  const formData = new FormData();

  formData.append('CountryId', country);
  formData.append('OrgFormId', orgFormId);
  formData.append('WorkWithNds', data.workWithNds);

  formData.append('Name', data.name);
  formData.append('FullName', data.fullName);
  formData.append('FirstName', data.firstName);
  formData.append('SecondName', data.secondName);
  formData.append('Patronymic', data.patronymic);
  formData.append('Email', data.email);
  formData.append('Phone', data.phone);
  formData.append('BankName', data.bankName);
  formData.append('BankAddress', data.bankAddress);
  formData.append('BankUnp', data.bankUnp);
  formData.append('Bic', data.bic);
  formData.append('AccountNumber', data.accountNumber);
  formData.append('Swift', data.swift);
  formData.append('correspondentBankName', data.correspondentBankName);
  formData.append('correspondentBankInn', data.correspondentBankInn);
  formData.append('correspondentBankBic', data.correspondentBankBic);
  formData.append('correspondentBankLegalAddress', data.correspondentBankLegalAddress);
  formData.append('correspondentBankAccount', data.correspondentBankAccount);
  formData.append('correspondentBankCheckingAccount', data.correspondentBankCheckingAccount);
  formData.append('Position', data.position);
  formData.append('LegalNumber', data.legalNumber);
  formData.append('LegalReason', orgFormId === 16 ? 'Свидетельство о регистрации ИП' : data.legalReason);
  formData.append('AtiCode', data.atiCode);

  formData.append('RegRegion', data.regRegion);
  formData.append('RegLocality', data.regLocality);
  formData.append('RegStreet', data.regStreet);
  formData.append('RegHouseNumber', data.regHouseNumber);
  formData.append('RegPremiseNumber', data.regPremiseNumber);
  formData.append('RegPostalCode', data.regPostalCode);
  formData.append('MailingRegion', data.mailingRegion);
  formData.append('MailingLocality', data.mailingLocality);
  formData.append('MailingStreet', data.mailingStreet);
  formData.append('MailingHouseNumber', data.mailingHouseNumber);
  formData.append('MailingPremiseNumber', data.mailingPremiseNumber);
  formData.append('MailingPostalCode', data.mailingPostalCode);

  formData.append('GuarantorFirstName', data.guarantorFirstName);
  formData.append('GuarantorSecondName', data.guarantorSecondName);
  formData.append('GuarantorPatronymic', data.guarantorPatronymic);
  formData.append('GuarantorPassportSerial', data.guarantorPassportSerial);
  formData.append('GuarantorPassportNumber', data.guarantorPassportNumber);
  formData.append('GuarantorPassportDate', data.guarantorPassportDate ? data.guarantorPassportDate.format('DD.MM.YYYY') : data.guarantorPassportDate);
  formData.append('GuarantorPassportRegistration', data.guarantorPassportRegistration);
  formData.append('GuarantorAddressFact', data.guarantorAddressFact);
  formData.append('GuarantorAddressRegistration', data.guarantorAddressRegistration);
  formData.append('GuarantorAddressFact', data.guarantorAddressFact);
  formData.append('GuarantorCountry', data.guarantorCountry);
  formData.append('GuarantorPhone', data.guarantorPhone);
  formData.append('GuarantorEmail', data.guarantorEmail);

  for (const [key, value] of Object.entries(files)) {
    if (Array.isArray(value)) {
      let count = 0;
      for (const file of value) {
        let fileName = (count > 0) ? key + count : key;

        formData.append(fileName, file);
        count++;
      }
    } else {
      formData.append(key, value);
    }
  }

  return await httpRequest
    .post("company/create-belarus-legal-entity", formData, {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function editBelarusLegalEntity(legalEntityData, legalEntityId) {
  const formData = new FormData();

  formData.append('LegalEntityId', legalEntityId);
  formData.append('Edit', true);

  for (const key in legalEntityData) {
    let keyName = key;
    keyName = keyName.charAt(0).toUpperCase() + keyName.slice(1);

    formData.append(keyName, legalEntityData[key]);
  }

  return await httpRequest.post('company/edit-belarus-legal-entity', formData).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getGetLegalEntityDocsList(orgFormID, countryID, isNeedPowerOfAttorneyForAPerson, isNDSPayer, purposeType, taxType = '') {
  return await httpRequest.get(
    `company/get-legal-entity-docs-list?OrgFormID=${orgFormID}&CountryID=${countryID}&IsNeedPowerOfAttorneyForAPerson=${isNeedPowerOfAttorneyForAPerson}&IsNDSPayer=${isNDSPayer}&PurposeType=${purposeType}&TaxType=${taxType}`
  ).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

/**
 * Изменить тип компании по order id
 * @param orderId ID Согласования
 * @param type Тип компании
 * @returns {Promise<any>}
 */
async function changeTypeByOrderId(orderId, type) {
  return await httpRequest
    .post('company/change-type-by-order-id', {
      orderId: orderId,
      type: type
    })
    .then((response) => {
      if (response.status === 200 || response.status === 202) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Запросить аккредитацию на международные рейсы
 * @param shipperCompanyId
 * @returns {Promise<any>}
 */
async function requestAllowInternational(shipperCompanyId) {
  return await httpRequest
    .post('company/request-allow-international', {
      shipperCompanyId: shipperCompanyId
    })
    .then((response) => {
      if (response.status === 200 || response.status === 202) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
