import React, {useEffect,useState} from 'react';
import {notification,Row,Col,Button,Upload,Icon} from 'antd';
import {companyService} from '../../../../services';
import FieldBlock from '../../../field-block';
import DividerBlock from "../../../divider-block";

export default function EditRusLegalEntityDocs({
                                                   legalEntityData,
                                                   docFields,
                                                   setDocFields,
                                                   fieldsErrors,
                                                   isNeedPowerOfAttorneyForAPerson,
                                                   setIsNeedPowerOfAttorneyForAPerson,
                                                   isNDSPayer,
                                                   setIsNDSPayer,
                                                   isConsignor,
}) {
    const [taxType, setTaxType] = useState(false);

    useEffect(() => {
        if (legalEntityData.WorkWithNds === 1) {
            setIsNDSPayer(true);
        } else {
            setIsNDSPayer(false)
        }

        if (legalEntityData.LegalReason === 'Доверенность (ООО)') {
            setIsNeedPowerOfAttorneyForAPerson(true);
        } else {
            setIsNeedPowerOfAttorneyForAPerson(false)
        }

        setTaxType(legalEntityData.TaxType);
    }, [legalEntityData]);

    const getFieldErrors = (fieldName) => {
        return typeof fieldsErrors[fieldName] !== "undefined"
            ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
                return fieldErrors + "; " + error;
            })
            : null;
    };    

    const getUploaderClass = (name, multiple = true) => {
        return {
            multiple: multiple,
            showUploadList: true,
            onRemove: (file) => {
                setDocFields({...docFields, [name]: null});
            },
            beforeUpload: (file, fileList) => {
                if (multiple) {
                    if (docFields[name]) {
                        setDocFields({...docFields, [name]: docFields[name].concat(fileList)});
                    } else {
                        setDocFields({...docFields, [name]: fileList});
                    }
                } else {
                    setDocFields({...docFields, [name]: file});
                }
                return false;
            },
            fileList: docFields[name],
            listType: 'picture',
        };
    };

    //TODO
    const docsRenderer = () => {
        if (legalEntityData.OrgFormId === 26) {
            return (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority} className="single-btn-upload" {...getUploaderClass('certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/>  Свидетельство о постановке индивидуального предпринимателя на учет в налоговом органе
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="certificateOfStateRegistration" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.certificateOfStateRegistration} className="single-btn-upload" {...getUploaderClass('certificateOfStateRegistration', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/>  Свидетельство о государственной регистрации
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="registeredMemorandumOfAssociation" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.registeredMemorandumOfAssociation} className="single-btn-upload" {...getUploaderClass('registeredMemorandumOfAssociation', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/>  Зарегистрированный Учредительный договор
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="decisionToCreate" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.decisionToCreate} className="single-btn-upload" {...getUploaderClass('decisionToCreate', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/>  Протокол или Решение о создании
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="requisites" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.requisites} className="single-btn-upload" {...getUploaderClass('requisites', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/>  Реквизиты с печатью
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    {!(!legalEntityData.IsProprietorType && isConsignor) ? (
                            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                                <FieldBlock name="copyOfThePassport" getErrors={getFieldErrors}>
                                    <Upload defaultFileList={docFields.copyOfThePassport} className="single-btn-upload" {...getUploaderClass('copyOfThePassport', true)}>
                                        <Button htmlType="button">
                                            <Icon type="upload" className="single-btn-upload__icon"/>  Копия паспорта
                                        </Button>
                                    </Upload>
                                </FieldBlock>
                            </Col>
                        ) : null
                    }
                </>
            );
        }

        if (legalEntityData.IsProprietorType) {
            return (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority} className="single-btn-upload" {...getUploaderClass('certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority')}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/>  Свидетельство о постановке индивидуального предпринимателя на учет в налоговом органе
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="partnerCard" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.partnerCard} className="single-btn-upload" {...getUploaderClass('partnerCard', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Карта партнера с образцом подписи и печати
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="copyOfThePassport" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.copyOfThePassport} className="single-btn-upload" {...getUploaderClass('copyOfThePassport', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Копия паспорта
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="certificateOfStateRegistration" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.certificateOfStateRegistration} className="single-btn-upload" {...getUploaderClass('certificateOfStateRegistration')}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Свидетельство о государственной регистрации в качестве индивидуального предпринимателя
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                </>
            )
        } else {
            return (
                <>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="partnerCard" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.partnerCard} className="single-btn-upload" {...getUploaderClass('partnerCard', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Карта партнера с образцом подписи и печати
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    {!isConsignor ? (
                            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                                <FieldBlock name="copyOfThePassport" getErrors={getFieldErrors}>
                                    <Upload defaultFileList={docFields.copyOfThePassport} className="single-btn-upload" {...getUploaderClass('copyOfThePassport', true)}>
                                        <Button htmlType="button">
                                            <Icon type="upload" className="single-btn-upload__icon"/> Копия паспорта
                                        </Button>
                                    </Upload>
                                </FieldBlock>
                            </Col>
                        ) : null
                    }
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="certificateOfStateRegistration" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.certificateOfStateRegistration} className="single-btn-upload" {...getUploaderClass('certificateOfStateRegistration')}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Свидетельство о государственной регистрации в качестве индивидуального предпринимателя
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="protocolOfTheGeneralMeetingOfParticipants" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.protocolOfTheGeneralMeetingOfParticipants} className="single-btn-upload" {...getUploaderClass('protocolOfTheGeneralMeetingOfParticipants', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Протокол общего собрания участников юридического лица с решением об избрании руководителя
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    {!isConsignor ? (
                            <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                                <FieldBlock name="proofOfOwnership" getErrors={getFieldErrors}>
                                    <Upload defaultFileList={docFields.proofOfOwnership} className="single-btn-upload" {...getUploaderClass('proofOfOwnership', true)}>
                                        <Button htmlType="button">
                                            <Icon type="upload" className="single-btn-upload__icon"/>  Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность
                                        </Button>
                                    </Upload>
                                </FieldBlock>
                            </Col>
                        ) : null
                    }
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="charterWithAllAmendments" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.charterWithAllAmendments} className="single-btn-upload" {...getUploaderClass('charterWithAllAmendments', true)}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Устав со всеми изменениями
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                        <FieldBlock name="registrationOfALegalEntityWithTaxAuthority" getErrors={getFieldErrors}>
                            <Upload defaultFileList={docFields.registrationOfALegalEntityWithTaxAuthority} className="single-btn-upload" {...getUploaderClass('registrationOfALegalEntityWithTaxAuthority')}>
                                <Button htmlType="button">
                                    <Icon type="upload" className="single-btn-upload__icon"/> Свидетельство о постановке на учет в налоговом органе
                                </Button>
                            </Upload>
                        </FieldBlock>
                    </Col>
                    {isNeedPowerOfAttorneyForAPerson &&
                    (
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="powerOfAttorneyForAPerson" getErrors={getFieldErrors}>
                                <Upload defaultFileList={docFields.powerOfAttorneyForAPerson} className="single-btn-upload" {...getUploaderClass('powerOfAttorneyForAPerson')}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/> Доверенность на лицо, подтверждающая полномочия лица, подписывающего договор
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                    )
                    }
                </>
            );
        }
    }

    return (
        <Row gutter={20}>
            <DividerBlock label="Загрузите сканы документов"/>

            {docsRenderer()}

            {(isNDSPayer && !isConsignor) && (
                ((taxType == 'common_nds') && (
                    <>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="incomeTaxForTheLastReportingPeriod" getErrors={getFieldErrors}>
                                <Upload defaultFileList={docFields.incomeTaxForTheLastReportingPeriod} fileList={docFields.incomeTaxForTheLastReportingPeriod} className="single-btn-upload" {...getUploaderClass('incomeTaxForTheLastReportingPeriod', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/> Налоговая декларация по прибыли за последний отчетный период
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="nDSReturnForTheLastReportingPeriod" getErrors={getFieldErrors}>
                                <Upload defaultFileList={docFields.nDSReturnForTheLastReportingPeriod} fileList={docFields.nDSReturnForTheLastReportingPeriod} className="single-btn-upload" {...getUploaderClass('nDSReturnForTheLastReportingPeriod', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/> Налоговая декларация по НДС за последний отчетный период
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="bookkeepingBalance" getErrors={getFieldErrors}>
                                <Upload defaultFileList={docFields.bookkeepingBalance} fileList={docFields.bookkeepingBalance} className="single-btn-upload" {...getUploaderClass('bookkeepingBalance', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/> Бухгалтерский баланс за последний отчетный период
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="certificateFromIFNSOnTheStatusOfSettlementsWithTheBudget" getErrors={getFieldErrors}>
                                <Upload defaultFileList={docFields.certificateFromIFNSOnTheStatusOfSettlementsWithTheBudget} fileList={docFields.certificateFromIFNSOnTheStatusOfSettlementsWithTheBudget} className="single-btn-upload" {...getUploaderClass('certificateFromIFNSOnTheStatusOfSettlementsWithTheBudget', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/> Справка из ИФНС о состоянии расчетов с бюджетом
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="staffingTable" getErrors={getFieldErrors}>
                                <Upload defaultFileList={docFields.staffingTable} fileList={docFields.staffingTable} className="single-btn-upload" {...getUploaderClass('staffingTable', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/> Штатное расписание
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                    </>
                )) 
                || 
                ((taxType == 'simple_nds') && (
                    <>
                        <Col lg={12} md={24} sm={24} xs={24} style={{margin:'0 0 15px 0'}}>
                            <FieldBlock name="documentsConfirmingTax" getErrors={getFieldErrors}>
                                <Upload defaultFileList={docFields.documentsConfirmingTax} fileList={docFields.documentsConfirmingTax} className="single-btn-upload" {...getUploaderClass('documentsConfirmingTax', true)}>
                                    <Button htmlType="button">
                                        <Icon type="upload" className="single-btn-upload__icon"/>Документы подтверждающие налогообложение
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                    </>
                ))
            )}
        </Row>
    );
}
