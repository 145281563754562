import { Layout, Row, Col } from "antd";
import Spinner from "../../spinner";
import React, { useState, useEffect } from "react";
import { userServices } from "../../../services";

export default function DriverPage() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if(!dataLoaded) {
      let user = userServices.getUser();
      console.log(user.info);
      setUserData(user.info);
      console.log(userData);
      setDataLoaded(true);
    }
  }, [dataLoaded]);

  return(<>
    <Spinner loading={!dataLoaded} tip="Загрузка...">
      <Layout
        tagName="section"
        style={{ marginTop: "50px", padding: "25px 0", background: "#fff", borderRadius: "11px", boxShadow: "0px 4px 10px rgba(130, 137, 147, 0.2)" }}
      >
        <Layout.Content style={{ padding: "0 24px", minHeight: 350 }} className="table-window">
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                ФИО:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.fio}
              </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                Роль:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.roleName}
              </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                Компания:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                  {userData.company && userData.company.name}
              </Col>
            </Row>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
              <Col lg={12} md={12} sm={12} xs={24} className="label dispatch" style={{ marginBottom: '10px' }}>
                Тип компании:
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} className="data" style={{ marginBottom: '10px', textAlign: "right" }}>
                {userData.company && userData.company.type}
              </Col>
            </Row>
        </Layout.Content>
      </Layout>
    </Spinner>
  </>);
}
