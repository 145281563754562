import React, { useEffect } from "react";
import {DateTime, FormatPrice, FullDate, ShortDate} from "../tools/delivery-tools";
import { REDUCTION_TYPE } from "../../helpers/delivery.helper";

export const TenderInfo = {
    common,
    price
}

function common({ delivery }) {
    const tender = delivery.Tender;
    const isReduction = (delivery.Type === REDUCTION_TYPE);
    let info = [];

    if(tender.LoadStart && tender.LoadEnd) {
        info.push(<>
            <div className="h-informer">Доступные даты отгрузки</div>
            <div className="b-informer">
                <div className="date red">{tender.LoadInterval}</div>
            </div>
        </>);
    }

    if(tender.TenderStart && tender.TenderEnd) {
        info.push(<>
            <div className="h-informer">Период проведения {isReduction ? 'редукциона' : 'аукциона'} (МСК)</div>
            <div className="b-informer" style={{ textAlign: "left" }}>
                <div className="date green"><b>от</b> <DateTime date={tender.TenderStart}/></div>
                <div className="date green"><b>до</b> <DateTime date={tender.TenderEnd}/></div>
            </div>
        </>);
    }

    return info;
}

function price ({ delivery }) {
    const tender = delivery.Tender;
    let info = [];

    if(tender.MaxPrice && tender.Step && false) { //Раз показываем ценой, эти данные не нужны
        info.push(<>
            <div className="col-pos-bot">
                <div className="double-bot clrfx">
                    <div className="informer-item">
                    <div className="h-informer">Начальная</div>
                    <div className="b-informer">
                        <div className="inf">{tender.MaxPrice}</div>
                    </div>
                    </div>
                    <div className="informer-item">
                    <div className="h-informer">Актуальная</div>
                    <div className="b-informer">
                        <div className="inf">{tender.ActualPrice}</div>
                    </div>
                    </div>
                </div>
            </div>
        </>);
    }

    return info;
}