/** @var string Delivery со статусом free*/
export const DELIVERY_FREE = 'free';

/** @var string Delivery со статусом booked*/
export const DELIVERY_BOOKED = 'booked';

/** @var string Delivery со статусом reserved*/
export const DELIVERY_RESERVED = 'reserved';

/** @var string Delivery со статусом agreed*/
export const DELIVERY_AGREED = 'agreed';

/** @var string Delivery со статусом awaiting-loading*/
export const DELIVERY_AWAITING_LOADING = 'awaiting-loading';

/** @var string Delivery со статусом in-tender*/
export const DELIVERY_IN_TENDER = 'in-tender';

/** @var string Delivery со статусом loading*/
export const DELIVERY_LOADING = 'loading';

/** @var string Delivery со статусом en-route*/
export const DELIVERY_ENROUTE = 'en-route';

/** @var string Order со статусом approved*/
export const DELIVERY_ORDER_APPROVED = 'approved';

/** @var string Order со статусом started*/
export const DELIVERY_ORDER_STARTED = 'started';

/** @var string Order со статусом filled*/
export const DELIVERY_ORDER_FILLED = 'filled';

/** @var string Order со статусом awaiting-agreed*/
export const DELIVERY_ORDER_AWAITING_AGREED = 'awaiting-agreed';

/** @var string Order со статусом agreed*/
export const DELIVERY_ORDER_AGREED = 'agreed';

/** @var string Offer со статусом new*/
export const DELIVERY_OFFER_NEW = 'new';

/** @var string Offer со статусом agreed*/
export const DELIVERY_OFFER_AGREED = 'agreed';

/** @var string Offer со статусом refused*/
export const DELIVERY_OFFER_REFUSED = 'refused';

/** @var string Offer со статусом irrelevant*/
export const DELIVERY_OFFER_IRRELEVANT = 'irrelevant';
