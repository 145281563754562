import React, {useEffect, useState} from "react";
import {deliveriesService, orderDeliveryService, userServices} from "../../../services";
import {Col, Modal, notification, Row, Spin, Popconfirm, Icon, Button, Table, Divider, Descriptions} from "antd";
import moment from "moment";
import ModalMakeCounteroffer from "../make-counteroffer";
import '../modals.css';
import {FormatPrice, FullDate} from "../../tools/delivery-tools";

function ApprovedBtn({
                       countApprovedOrdersForDistCenter,
                       maxCountLoadAutoDistCenter,
                       onApproveOrderDelivery,
                       orderDeliveryId,
                       creatorRole,
                       status,
                       setLoading,
                       user,
                       isAnySumApprove,
                       minOrdersToApprove,
                       setNeedUpdateContact,
                       ordersCount,
                       dateLoading,
                       orderItem,
                       accessToApproveOrders
                     }) {
  const [showModalCounteroffer, setShowModalCounteroffer] = useState(false);
  const onConfirmApproveOrderDelivery = (e) => {
    onApproveOrderDelivery();
  };

  const countString = `${countApprovedOrdersForDistCenter}`;
  const plannedString = countString[countString.length - 1] === '1' ? 'запланирована' : 'запланировано';
  const loadsString = countString[countString.length - 1] === '1' ? 'загрузка' : countString[countString.length - 1] > '1' && countString[countString.length - 1] < '5' ? 'загрузки' : 'загрузок';

  if (status === 'counteroffer') {
    if (orderItem.hasOwnProperty('Counteroffer') && orderItem.Counteroffer.hasOwnProperty('Price')) {
      return (
        <div>
          Ожидание действия по встречному предложению<br/><br/>
          <i>Цена: {orderItem.Counteroffer.Price.toLocaleString('ru')}</i>
        </div>
      );
    }

    return (
      <div>
        Ожидание действия по встречному предложению
      </div>
    );

  }

  const isApproveButtonDisabled = ((!isAnySumApprove && !user.isAccessTo('order_any_sum_approve')) || (ordersCount < minOrdersToApprove));
  if (countApprovedOrdersForDistCenter >= maxCountLoadAutoDistCenter && countApprovedOrdersForDistCenter !== null && maxCountLoadAutoDistCenter !== null) {
    return (
      <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
          {accessToApproveOrders ? (
              <Popconfirm
                  okText="Да"
                  cancelText="Нет"
                  placement="topRight"
                  onConfirm={onConfirmApproveOrderDelivery}
                  title={`На этот день уже ${plannedString} ${countApprovedOrdersForDistCenter} ${loadsString}. Вы уверены?`}
                  icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
              >
                  <Button
                      disabled={isApproveButtonDisabled}
                      htmlType="button" className="btn typical-btn typical-btn-green typical-btn-xs">
                      Утвердить
                  </Button>
              </Popconfirm>
          ) : null}
        {(user.isAccessTo('make_counteroffer') && status !== 'counteroffer') ? (
          <Button htmlType="button" className="btn typical-btn typical-btn-blue typical-btn-xs" onClick={
            () => {
              setShowModalCounteroffer(true);
            }
          }>
            Предложение
          </Button>
        ) : null}
        <ModalMakeCounteroffer
          isShow={showModalCounteroffer}
          setIsShow={setShowModalCounteroffer}
          orderDeliveryId={orderDeliveryId}
          setLoadingMainModal={setLoading}
          orderDateLoading={dateLoading}
          setNeedUpdateContact={setNeedUpdateContact}
        />
      </div>
    );
  } else {
    return (
      <div>
          {accessToApproveOrders ? (
              <Button
                  disabled={isApproveButtonDisabled}
                  htmlType="button" className="btn typical-btn typical-btn-green typical-btn-xs"
                  onClick={onApproveOrderDelivery}>
                  Утвердить
              </Button>
          ) : null}
        {((creatorRole === 'carrier_owner' || creatorRole === 'carrier_dispatcher' || creatorRole === 'company_person') && user.isAccessTo('make_counteroffer')) ? (
          <Button htmlType="button" className="btn typical-btn typical-btn-blue typical-btn-xs" onClick={
            () => {
              setShowModalCounteroffer(true);
            }
          }>
            Предложение
          </Button>
        ) : null}
        <ModalMakeCounteroffer
          isShow={showModalCounteroffer}
          setIsShow={setShowModalCounteroffer}
          orderDeliveryId={orderDeliveryId}
          setLoadingMainModal={setLoading}
          orderDateLoading={dateLoading}
          setNeedUpdateContact={setNeedUpdateContact}
        />
      </div>
    );
  }
}

function TableHeaders() {
  return (
    <thead>
    <tr>
      <th>Дата заявки</th>
      <th>Перевозчик</th>
      <th>Ответственный</th>
      <th>Телефон</th>
      {<th>Дата загрузки</th>}
      <th>Ставка</th>
      {<th>Предоплата</th>}
      <th/>
    </tr>
    </thead>
  );
}

function DeliveryInfo({deliveryData}) {
  if (deliveryData) {
    const prices = deliveryData.Prices.map((elem) => {
      return (
        <div className="delivery-bit-value">
          <div className="delivery-bit-label">{elem.PaymentType.Title}</div>
          <FormatPrice price={elem.Price} showPrice={true} currencySymbol={elem.PaymentType.CurrencySymbol}/>
        </div>
      );
    });

    return (
      <div className="delivery-info-modal">
        <Col lg={8} md={12} sm={12} sx={24}>
          <p className="delivery-flight">
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                style={{marginRight: "10px"}}>
              <path
                d="M7.22717 0.00375168C3.57173 -0.119438 0.569824 2.80709 0.569824 6.43469C0.569824 10.5516 4.52067 13.5394 6.73511 17.8362C6.84754 18.0544 7.16159 18.0547 7.27441 17.8365C9.27769 13.9703 12.7016 11.3777 13.3361 7.65299C13.9931 3.79863 11.1348 0.135493 7.22717 0.00375168ZM7.00444 9.80519C5.14299 9.80519 3.63394 8.2961 3.63394 6.43469C3.63394 4.57328 5.14303 3.06419 7.00444 3.06419C8.86589 3.06419 10.375 4.57328 10.375 6.43469C10.375 8.2961 8.86589 9.80519 7.00444 9.80519Z"
                fill="#1CAA53"/>
            </svg>
            Рейс: <span style={{color: "#1CAA53"}}>№ {deliveryData.NumberDelivery}</span></p>
          <p className="delivery-date">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                style={{marginRight: "10px"}}>
              <path
                d="M15 4.58333V12.2917C15 13.7874 13.7874 15 12.2917 15H2.70833C1.21256 15 0 13.7874 0 12.2917V4.58333H15ZM3.54167 10C2.96637 10 2.5 10.4664 2.5 11.0417C2.5 11.617 2.96637 12.0833 3.54167 12.0833C4.11696 12.0833 4.58333 11.617 4.58333 11.0417C4.58333 10.4664 4.11696 10 3.54167 10ZM7.5 10C6.9247 10 6.45833 10.4664 6.45833 11.0417C6.45833 11.617 6.9247 12.0833 7.5 12.0833C8.0753 12.0833 8.54167 11.617 8.54167 11.0417C8.54167 10.4664 8.0753 10 7.5 10ZM3.54167 6.25C2.96637 6.25 2.5 6.71637 2.5 7.29167C2.5 7.86696 2.96637 8.33333 3.54167 8.33333C4.11696 8.33333 4.58333 7.86696 4.58333 7.29167C4.58333 6.71637 4.11696 6.25 3.54167 6.25ZM7.5 6.25C6.9247 6.25 6.45833 6.71637 6.45833 7.29167C6.45833 7.86696 6.9247 8.33333 7.5 8.33333C8.0753 8.33333 8.54167 7.86696 8.54167 7.29167C8.54167 6.71637 8.0753 6.25 7.5 6.25ZM11.4583 6.25C10.883 6.25 10.4167 6.71637 10.4167 7.29167C10.4167 7.86696 10.883 8.33333 11.4583 8.33333C12.0336 8.33333 12.5 7.86696 12.5 7.29167C12.5 6.71637 12.0336 6.25 11.4583 6.25ZM12.2917 0C13.7874 0 15 1.21256 15 2.70833V3.33333H0V2.70833C0 1.21256 1.21256 0 2.70833 0H12.2917Z"
                fill="#1CAA53"/>
            </svg>
            Дата создания: {moment(deliveryData.DateCreated).format("DD.MM.YYYY")}</p>
        </Col>
        <Col lg={8} md={12} sm={12} sx={24} style={{textAlign: "center"}}>
          <p
            className="delivery-address"> {deliveryData.PlaceLoad.City} &#10230;  {deliveryData.PlacesUnload[deliveryData.PlacesUnload.length - 1].City}</p>
        </Col>

        <Col lg={8} md={24} sm={24} sx={24}>
          <Row>
            <Col lg={12} xs={24} style={{textAlign: "right"}}>
              <div className="delivery-left-bit">
                <p className="delivery-bit">Ставка:</p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="delivery-right-bit">
                {prices}
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return  null
  }
}

function ContentModalListOrders({
                                  setErrorMsg,
                                  ordersData,
                                  deliveryData,
                                  loading,
                                  errorMsg,
                                  setLoading,
                                  setNeedUpdateDeliveryList,
                                  setStopListUpdate,
                                  setIsShow,
                                  setNeedUpdateContact,
                                  isShow,
                                  user
                                }) {
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }
  if (loading) {
    return (
      <Row gutter={16} className="add-padding-10">
        <Col span={24} style={{textAlign: 'center'}}>
          <Spin spinning={loading} tip="Загрузка..."/>
        </Col>
      </Row>
    );
  }

  const ordersCount = ordersData.OrdersCount;

  const ActionsButtons = ({orderItem}) => {
    const [orderLimit, setOrderLimit] = useState(null);
    if (!orderLimit) {
      orderDeliveryService.checkOrderLimit(orderItem.Id)
        .then((response) => {
          setOrderLimit(response);
        })
        .catch((errorMsg) => {
          setErrorMsg(errorMsg);
        });
    }
    const [orderStatus, setOrderStatus] = useState(orderItem.Status);
    const approveOrderDelivery = () => {

      setLoading(true);

      orderDeliveryService.approveOrder(orderItem.Id)
        .then((response) => {
          notification.success({
            message: "Одобрение заявки",
            description: response.message,
          });

          setLoading(false);
          setIsShow(false);
          // setNeedUpdateDeliveryList(true);
          // setStopListUpdate(false);
        })
        .catch((error) => {
          notification.error({
            message: "Одобрение заявки",
            description: error,
          });

          setLoading(false);
        });
    };

    // if (ordersData.Permissions.IsAccessToApproveOrders) {
    //   return (
    //         <ApprovedBtn
    //             countApprovedOrdersForDistCenter={orderLimit ? orderLimit.nowCount : null}
    //             maxCountLoadAutoDistCenter={orderLimit ? orderLimit.maxOrder : null}
    //             onApproveOrderDelivery={approveOrderDelivery}
    //             orderDeliveryId={orderItem.Id}
    //             creatorRole={orderItem.CreatorRole}
    //             setLoading={setOrderStatus}
    //             setNeedUpdateContact={setNeedUpdateContact}
    //             status={orderStatus}
    //             dateLoading={orderItem.Date}
    //             user={user}
    //             isAnySumApprove={orderItem.IsAnySumApprove}
    //             minOrdersToApprove={orderItem.MinOrdersToApprove}
    //             ordersCount={ordersCount}
    //             orderItem={orderItem}
    //         />
    //   );
    // }

      return (
          <ApprovedBtn
              countApprovedOrdersForDistCenter={orderLimit ? orderLimit.nowCount : null}
              maxCountLoadAutoDistCenter={orderLimit ? orderLimit.maxOrder : null}
              onApproveOrderDelivery={approveOrderDelivery}
              orderDeliveryId={orderItem.Id}
              creatorRole={orderItem.CreatorRole}
              setLoading={setOrderStatus}
              setNeedUpdateContact={setNeedUpdateContact}
              status={orderStatus}
              dateLoading={orderItem.Date}
              user={user}
              isAnySumApprove={orderItem.IsAnySumApprove}
              minOrdersToApprove={orderItem.MinOrdersToApprove}
              ordersCount={ordersCount}
              orderItem={orderItem}
              accessToApproveOrders={ordersData.Permissions.IsAccessToApproveOrders}
          />
      );
  }

  const columns = [
    {
      render: (record) => (
        <div style={{padding: "10px 0"}}>
          <Descriptions size="small"
                        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
                        layout="vertical"
          >
            <Descriptions.Item
              span="2"
              label={<b>Перевозчик</b>}
            >{(record.Company && record.Company.Contact && record.Company.LegalEntity.Name)
              ? record.Company.LegalEntity.Name
              : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Ответственный</b>}>{(record.Company && record.Company.Contact && record.Company.Contact.Fio)
              ? record.Company.Contact.Fio
              : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Телефон</b>}>{(record.Company && record.Company.Contact && record.Company.Contact.Phone)
              ? record.Company.Contact.Phone
              : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Дата заявки</b>}>{moment(record.DateCreated).format('D.MM.YYYY HH:mm')}</Descriptions.Item>
            <Descriptions.Item label={<b>Дата загрузки</b>}>{moment(record.Date).format('D.MM.YYYY')}</Descriptions.Item>
            <Descriptions.Item label={<b>Ставка</b>}>
              <div className="order-price-block">
                <div>{(record.Price).toLocaleString('ru')}</div>
                <span
                  className="payment-type">{record.paymentType === 'null-payment-type' ? '-' : record.PaymentType}</span>
              </div>
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Предоплата</b>}>{record.PrepaymentPercent > 0 ? record.PrepaymentPercent + '%' : 'нет'}</Descriptions.Item>
          </Descriptions>
        </div>
      ),
      className: "desktop-hidden--md"
    },
    {
      title: "Дата заявки",
      render: (record) => (
        <React.Fragment>
          {moment(record.DateCreated).format('D.MM.YYYY HH:mm')}
        </React.Fragment>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: 'Перевозчик',
      render: (record) => (
        <>{(record.Company && record.Company.Contact && record.Company.LegalEntity.Name)
          ? record.Company.LegalEntity.Name
          : '-'}</>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: <>Ответственный/<br/>Телефон</>,
      render: (record) => (
        <>
          <div>
            {(record.Company && record.Company.Contact && record.Company.Contact.Fio)
              ? record.Company.Contact.Fio
              : '-'}
          </div>
          <div>
            <b>{(record.Company && record.Company.Contact && record.Company.Contact.Phone)
              ? record.Company.Contact.Phone
              : '-'}</b>
          </div>
        </>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: 'Дата загрузки',
      render: (record) => (
        <>{moment(record.Date).format('D.MM.YYYY')}</>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: "Ставка",
      render: (record) => (
        <div className="order-price-block">
          <div>{(record.Price).toLocaleString('ru')}</div>
          <span className="payment-type">{record.paymentType === 'null-payment-type' ? '-' : record.PaymentType}</span>
        </div>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: "Предоплата",
      render: (record) => (
        <>{record.PrepaymentPercent > 0 ? record.PrepaymentPercent + '%' : 'нет'}</>
      ),
      className: "mobile-hidden--md"
    },
    {
      render: (record) => <ActionsButtons orderItem={record}/>,
      //className: "mobile-hidden--md"
    }
  ];

  return (
    <>
      <DeliveryInfo deliveryData={deliveryData}/>
      <Table
        columns={columns}
        dataSource={ordersData.Orders}
        className={"employees-table"}
        rowKey={"id"}
        size="small"
      />
    </>
  );
}

export default function ModalListOrdersDelivery({
                                                  isShow,
                                                  setIsShow,
                                                  deliveryId,
                                                  setNeedUpdateDeliveryList,
                                                  setStopListUpdate,
                                                  delivery
                                                }) {
  const [loading, setLoading] = useState(false);
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [ordersData, setOrdersData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const user = userServices.getUser();
  const [needUpdateContact, setNeedUpdateContact] = useState(false);

  useEffect(() => {
    if (isShow && !loadingContentModal) {
      setLoading(true);
      setLoadingContentModal(true);
    }
  }, [isShow]);

  if (loadingContentModal) {
    deliveriesService.getListNewOrdersDelivery(deliveryId)
      .then((response) => {
        setLoadingContentModal(false);
        setOrdersData(response);
        setLoading(false);
      })
      .catch((errorMsg) => {
        setErrorMsg(errorMsg);
      })
  }

  useEffect(() => {
    if (needUpdateContact) {
      deliveriesService.getListNewOrdersDelivery(deliveryId)
        .then((response) => {
          setLoadingContentModal(false);
          setOrdersData(response);
          setLoading(false);
          setNeedUpdateContact(false)
        })
        .catch((errorMsg) => {
          setErrorMsg(errorMsg);
        })
    }
  }, [needUpdateContact])

  return (
    <Modal
      title="Полученные заявки"
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        // setNeedUpdateDeliveryList(false);
        // setStopListUpdate(false);
      }}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <ContentModalListOrders
            loading={loading}
            errorMsg={errorMsg}
            ordersData={ordersData}
            deliveryData={delivery}
            //setStopListUpdate={setStopListUpdate}
            setLoading={setLoading}
            setNeedUpdateContact={setNeedUpdateContact}
            setErrorMsg={setErrorMsg}
            setIsShow={setIsShow}
            // setNeedUpdateDeliveryList={setNeedUpdateDeliveryList}
            isShow={isShow}
            user={user}
          />
        </Col>
      </Row>
    </Modal>
  );
}
