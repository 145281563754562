import React, { useState, useEffect } from 'react';
import {Button, Col, Modal, Row, Spin, Upload, Icon, DatePicker, notification, Checkbox} from "antd";
import { driverService } from "../../../services";
import localeDatePicker from "antd/lib/date-picker/locale/ru_RU";
import InputField from '../../input-field';
import FieldBlock from '../../field-block';
import DividerBlock from '../../divider-block';
import SelectField from "../../select-field";
import moment from "moment";
import '../modals.css';
import PhoneField from "../../phone-field";

export default function ModalCreateDriver({
    companyId,
    isShow,
    setIsShow,
    setShowAttachDriverOrderDelivery,
    editing,
    blockedFields,
    defaultFieldErrors,
    defaultFieldsData,
    valuesInvalidFields,
    setValuesInvalidFields,
    errorsInvalidFields,
    setIsNeedUpdateList = false
}) {

    const [loading, setLoading] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState([]);

    const [fio, setFio] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneCountryID, setPhoneCountryID] = useState('');
    const [additionalPhoneOne, setAdditionalPhoneOne] = useState('');
    const [additionalPhoneTwo, setAdditionalPhoneTwo] = useState('');
    const [serialPassport, setSerialPassport] = useState('');
    const [numberPassport, setNumberPassport] = useState('');
    const [divisionPassport, setDivisionPassport] = useState('');
    const [datePassport, setDatePassport] = useState(null);
    const [registrationPassport, setRegistrationPassport] = useState('');
    const [serialDriverLicense, setSerialDriverLicense] = useState('');
    const [numberDriverLicense, setNumberDriverLicense] = useState('');
    const [readTermsOfUse, setReadTermsOfUse] = useState(null);
    const [dateDriverLicense, setDateDriverLicense] = useState(null);
    const [scanHomePagePassport, setScanHomePagePassport] = useState(null);
    const [scanRegPagePassport, setScanRegPagePassport] = useState(null);
    const [scanDriverLicense1, setScanDriverLicense1] = useState(null);
    const [scanDriverLicense2, setScanDriverLicense2] = useState(null);
    const [regCountry, setRegCountry] = useState('Россия');
    const [defaultData, setDefaultData] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [inn, setInn] = useState('');

    let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px' };
    let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }

    const onClickCreateDriver = () => {
        setLoading(true);

        const driverData = {
            companyId: companyId,
            fio: fio,
            phone: phone,
            phoneCountryID: phoneCountryID,
            additionalPhoneOne: additionalPhoneOne,
            additionalPhoneTwo: additionalPhoneTwo,
            passportSerial: serialPassport,
            passportNumber: numberPassport,
            passportDivision: divisionPassport,
            passportDate: (datePassport)
                ? datePassport.format('YYYY-MM-DD')
                : null,
            registration: registrationPassport,
            driverLicenseSerial: serialDriverLicense,
            driverLicenseNumber: numberDriverLicense,
            driverLicenseDate: (dateDriverLicense)
                ? dateDriverLicense.format('YYYY-MM-DD')
                : null,
            scanPassportHomePage: scanHomePagePassport,
            scanPassportRegPage: scanRegPagePassport,
            scanDriverLicense1: scanDriverLicense1,
            scanDriverLicense2: scanDriverLicense2,
            regCountry: regCountry,
            readTermsOfUse: readTermsOfUse ? 1 : 0,
            inn: inn,
        };

        driverService.create(driverData)
            .then((response) => {
                setLoading(false);

                if (response.status === 'success') {

                    notification.success({
                        message: "Создание водителя",
                        description: response.message,
                    });
                    setFio(null);
                    setPhone(null);
                    setAdditionalPhoneOne(null);
                    setAdditionalPhoneTwo(null);
                    setSerialPassport(null);
                    setNumberPassport(null);
                    setDivisionPassport(null);
                    setRegistrationPassport(null);
                    setDatePassport(null);
                    setSerialDriverLicense(null);
                    setDateDriverLicense(null);
                    setNumberDriverLicense(null);
                    setScanHomePagePassport(null);
                    setScanRegPagePassport(null);
                    setScanDriverLicense1(null);
                    setScanDriverLicense2(null);
                    setRegCountry('Россия');
                    setIsShow(false);
                    setShowAttachDriverOrderDelivery(true);
                    setIsNeedUpdateList(true);
                    setReadTermsOfUse(false);
                    setInn(null);
                } else if (response.status === 'error') {

                    setFieldsErrors(response.errors);
                }

            })
            .catch((error) => {
                notification.error({
                    message: "Создание водителя",
                    description: error,
                });

                setLoading(false);
            });
    };

    const getFieldErrors = (nameField) => {
        if (!editing) {
            return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
                ? fieldsErrors[nameField][0]
                : null;
        } else {
            return (errorsInvalidFields && typeof errorsInvalidFields[nameField] !== "undefined")
                ? errorsInvalidFields[nameField][0]
                : null;
        }
    };

    const getFieldDisabled = (nameField) => {
        return blockedFields && blockedFields.find(item => item === nameField) !== undefined;
    }

    const getDefaultData = (nameField) => {
        return (defaultData && defaultData[nameField].length && defaultData[nameField] !== "undefined")
            ? defaultData[nameField]
            : null
    }

    const uploaderPropsScanHomePagePassport = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanHomePagePassport(null);
        },
        beforeUpload: (file) => {
            setScanHomePagePassport(file);
            if (valuesInvalidFields) {
                setValuesInvalidFields({
                    ...valuesInvalidFields,
                    scanPassportHomePage: file
                });
            }
            return false;
        },
        fileList: (editing && scanHomePagePassport && scanHomePagePassport.length)
            ? [{
                uid: '-1',
                name: '',
                url: !(scanHomePagePassport instanceof File) ? scanHomePagePassport : URL.createObjectURL(scanHomePagePassport),
                status: 'done'
            }]
            : (!editing && scanHomePagePassport) ?
                [{
                    uid: '-1',
                    name: scanHomePagePassport.name,
                    url: !(scanHomePagePassport instanceof File) ? scanHomePagePassport : URL.createObjectURL(scanHomePagePassport),
                    status: 'done'
                }]
                : [],
        listType: (!editing && scanHomePagePassport) ? 'text' : 'picture'
    };
    const uploaderPropsScanRegPagePassport = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanRegPagePassport(null);
        },
        beforeUpload: (file) => {
            setScanRegPagePassport(file);
            if (valuesInvalidFields) {
                setValuesInvalidFields({
                    ...valuesInvalidFields,
                    scanPassportRegPage: file
                });
            }
            return false;
        },
        fileList: (editing && scanRegPagePassport && scanRegPagePassport.length)
            ? [{
                uid: '-2',
                name: "",
                url: !(scanRegPagePassport instanceof File) ? scanRegPagePassport : URL.createObjectURL(scanRegPagePassport),
                status: 'done'
            }]
          : (!editing && scanRegPagePassport) ?
            [{
                uid: '-2',
                name: scanRegPagePassport.name,
                url: !(scanRegPagePassport instanceof File) ? scanRegPagePassport : URL.createObjectURL(scanRegPagePassport),
                status: 'done'
            }]
            : [],
        listType: (!editing && scanRegPagePassport) ? 'text' : 'picture'
    };

    const uploaderPropsScanDriverLicense1 = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanDriverLicense1(null);
        },
        beforeUpload: (file) => {
            setScanDriverLicense1(file);
            if (valuesInvalidFields) {
                setValuesInvalidFields({
                    ...valuesInvalidFields,
                    scanDriverLicense1: file
                });
            }
            return false;
        },
        fileList: (editing && scanDriverLicense1 && scanDriverLicense1.length)
            ? [{
                uid: '-3',
                name: "",
                url: !(scanDriverLicense1 instanceof File) ? scanDriverLicense1 : URL.createObjectURL(scanDriverLicense1),
                status: 'done'
            }]
          : (!editing && scanDriverLicense1) ?
            [{
                uid: '-3',
                name: scanDriverLicense1.name,
                url: !(scanDriverLicense1 instanceof File) ? scanDriverLicense1 : URL.createObjectURL(scanDriverLicense1),
                status: 'done'
            }]
            : [],
        listType: (!editing && scanDriverLicense1) ? 'text' : 'picture'
    };
    const uploaderPropsScanDriverLicense2 = {
        multiple: false,
        showUploadList: true,
        onRemove: () => {
            setScanDriverLicense2(null);
        },
        beforeUpload: (file) => {
            setScanDriverLicense2(file);
            if (valuesInvalidFields) {
                setValuesInvalidFields({
                    ...valuesInvalidFields,
                    scanDriverLicense2: file
                });
            }
            return false;
        },
        fileList: (editing && scanDriverLicense2 && scanDriverLicense2.length)
            ? [{
                uid: '-4',
                name: "",
                url: !(scanDriverLicense2 instanceof File) ? scanDriverLicense2 : URL.createObjectURL(scanDriverLicense2),
                status: 'done'
            }]
          : (!editing && scanDriverLicense2) ?
            [{
                uid: '-4',
                name: scanDriverLicense2.name,
                url: !(scanDriverLicense2 instanceof File) ? scanDriverLicense2 : URL.createObjectURL(scanDriverLicense2),
                status: 'done'
            }]
            : [],
        listType: (!editing && scanDriverLicense2) ? 'text' : 'picture'
    };

    useEffect(() => {
        if (defaultFieldErrors) {
            setFieldsErrors(defaultFieldErrors);
        }
    }, [defaultFieldErrors]);

    useEffect(() => {
        if (defaultFieldsData) {
            setDefaultData(defaultFieldsData);
            setDatePassport(defaultFieldsData.passportDate ? moment(defaultFieldsData.passportDate, 'YYYY-MM-DD') : null);
            setDateDriverLicense(defaultFieldsData.driverLicenseDate ? moment(defaultFieldsData.driverLicenseDate, 'YYYY-MM-DD') : null);
            setScanHomePagePassport(defaultFieldsData.scanPassportHomePage);
            setScanRegPagePassport(defaultFieldsData.scanPassportRegPage);
            setScanDriverLicense1(defaultFieldsData.scanDriverLicense1);
            setScanDriverLicense2(defaultFieldsData.scanDriverLicense2);
            setRegCountry(defaultFieldsData.regCountry);
            setReadTermsOfUse(defaultFieldsData.readTermsOfUse);
            setPhoneCountryID(defaultFieldsData.phoneCountryID);
            setPhone(defaultFieldsData.phone);
        }
    }, [defaultFieldsData]);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const handleCancel = () => setPreviewVisible(false);

    const handleOpenTermsOfUse = () => {
        window.open('/terms-of-use?type=driver&countryId='+ 1, '_blank').focus();
    }

    useEffect(() => {
        if (regCountry !== 'Россия') {
            setSerialPassport('');

            if(defaultData) {
                setDefaultData({
                    ...defaultData,
                    passportSerial: ''
                });
            }

            if (valuesInvalidFields) {
                setValuesInvalidFields({
                    ...valuesInvalidFields,
                    passportSerial: ''
                });
            }
        }
    }, [regCountry, isShow, loading]);

    if (!editing) {
        return (
            <Modal
                title={"Добавление водителя"}
                cancelText="Закрыть"
                className="modal-window"
                align={null}
                visible={isShow}
                onCancel={() => {
                    setLoading(false);
                    setIsShow(false);
                    setShowAttachDriverOrderDelivery(true);
                }}
                footer={[
                    <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onClickCreateDriver}>Добавить</Button>,
                ]}
            >
                <Spin spinning={loading} tip="Загрузка...">
                    <Row gutter={[16, 24]}>
                        <Col lg={12} md={24} sm={24} xs={24} span={12}>
                            <InputField
                                name="fio" label="ФИО"
                                value={fio} setValue={setFio}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                        {/*<Col lg={6} md={12} sm={12} xs={12} span={6}>*/}
                        {/*    <InputField*/}
                        {/*        name="phone" label="Телефон"*/}
                        {/*        value={phone} setValue={setPhone}*/}
                        {/*        getFieldErrors={getFieldErrors}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        <Col lg={6} md={12} sm={12} xs={12} span={6}>
                            <SelectField
                                title="Страна регистрации"
                                name="regCountry"
                                selectedValue={regCountry}
                                values={[
                                    { Id: 'Россия', Name: 'Россия' },
                                    { Id: 'Беларусь', Name: 'Беларусь' },
                                    { Id: 'Казахстан', Name: 'Казахстан' },
                                    { Id: 'Другая страна', Name: 'Другая страна' }
                                ]}
                                onChange={(value) => {
                                    setRegCountry(value);
                                }}
                                getErrors={getFieldErrors}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={24} sm={24} xs={24} span={12}>
                            <PhoneField
                                getErrors={getFieldErrors}
                                countryFieldName="phoneCountryID"
                                phoneFieldName="phone"
                                title="Моб. номер"
                                selectedCountryID={phoneCountryID}
                                onChangeCountry={(value) => {
                                    setPhoneCountryID(value);
                                }}
                                phoneNumber={phone}
                                onChangePhone={(e) => {
                                    setPhone(e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <InputField
                                name="additionalPhoneOne" label="Доп. номер"
                                value={additionalPhoneOne} setValue={setAdditionalPhoneOne}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <InputField
                                name="additionalPhoneTwo" label="Доп. номер"
                                value={additionalPhoneTwo} setValue={setAdditionalPhoneTwo}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                        {regCountry === 'Россия' ? <Col lg={6} md={12} sm={12} xs={12}>
                            <InputField
                                name="passportSerial" label="Серия паспорта"
                                value={serialPassport} setValue={setSerialPassport}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col> : null}
                        <Col lg={regCountry === 'Россия' ? 6 : 12} md={12} sm={12} xs={12}>
                            <InputField
                                name="passportNumber" label="Номер паспорта"
                                value={numberPassport} setValue={setNumberPassport}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <InputField
                                name="passportDivision" label="Подразделение"
                                value={divisionPassport} setValue={setDivisionPassport}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <FieldBlock title="Дата выдачи" name="passportDate" getErrors={getFieldErrors}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    defaultValue={datePassport}
                                    format="DD.MM.YYYY"
                                    size="normal"
                                    name="passportDate"
                                    locale={localeDatePicker}
                                    allowClear={false}
                                    onChange={(date) => {
                                        setDatePassport(date);
                                    }}
                                />
                            </FieldBlock>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <InputField
                                name="registration" label="Прописка (страна, город, улица, дом, кв)"
                                value={registrationPassport} setValue={setRegistrationPassport}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <InputField
                                name="driverLicenseSerial" label="Серия ВУ"
                                value={serialDriverLicense} setValue={setSerialDriverLicense}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <InputField
                                name="driverLicenseNumber" label="Номер ВУ"
                                value={numberDriverLicense} setValue={setNumberDriverLicense}
                                getFieldErrors={getFieldErrors}
                            />
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <FieldBlock title="Дата выдачи ВУ" name="driverLicenseDate" getErrors={getFieldErrors}>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    defaultValue={dateDriverLicense}
                                    format="DD.MM.YYYY"
                                    size="normal"
                                    name="driverLicenseDate"
                                    locale={localeDatePicker}
                                    allowClear={false}
                                    onChange={(date) => {
                                        setDateDriverLicense(date);
                                    }}
                                />
                            </FieldBlock>
                        </Col>
                        {/*<Col lg={12} md={24} sm={24} xs={24}>*/}
                        {/*    <FieldBlock name="readTermsOfUse" getErrors={getFieldErrors}>*/}
                        {/*        <Checkbox*/}
                        {/*            name="readTermsOfUse"*/}
                        {/*            checked={readTermsOfUse}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                setReadTermsOfUse(e.target.checked)*/}
                        {/*            }}*/}
                        {/*            style={{fontSize: "10pt", paddingTop: "15px"}}*/}
                        {/*        >*/}
                        {/*            Водитель ознакомлен с <span onClick={handleOpenTermsOfUse}>пользовательским соглашением</span>*/}
                        {/*        </Checkbox>*/}
                        {/*    </FieldBlock>*/}
                        {/*</Col>*/}
                    </Row>
                    {regCountry === 'Россия' ? <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <InputField
                                name="inn" label="ИНН"
                                value={inn} setValue={setInn}
                                getFieldErrors={getFieldErrors}
                                getFieldDisabled={getFieldDisabled}
                                onInputChange={(e) => {
                                    setDefaultData({
                                        ...defaultData,
                                        inn: e.target.value
                                    })
                                }}
                            />
                        </Col>
                    </Row> : null}
                    <span className="divider-label">Загрузите сканы документов</span>
                    <DividerBlock style={dividerStyle} />
                    <Row gutter={[16, 24]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <FieldBlock name="scanPassportHomePage" getErrors={getFieldErrors}>
                                <Upload className="single-btn-upload" {...uploaderPropsScanHomePagePassport}>
                                    <Button htmlType="button">
                                        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                            <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                            <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                            <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                        </svg> Скан главной страницы паспорта
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <FieldBlock name="scanPassportRegPage" getErrors={getFieldErrors}>
                                <Upload className="single-btn-upload" {...uploaderPropsScanRegPagePassport}>
                                    <Button htmlType="button">
                                        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                            <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                            <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                            <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                        </svg> Скан страницы с пропиской
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <FieldBlock name="scanDriverLicense1" getErrors={getFieldErrors}>
                                <Upload className="single-btn-upload" {...uploaderPropsScanDriverLicense1}>
                                    <Button htmlType="button">
                                        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                            <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                            <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                            <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                        </svg> Скан 1 стороны ВУ
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24}>
                            <FieldBlock name="scanDriverLicense2" getErrors={getFieldErrors}>
                                <Upload className="single-btn-upload" {...uploaderPropsScanDriverLicense2}>
                                    <Button htmlType="button">
                                        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                            <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                            <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                            <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                        </svg> Скан 2 стороны ВУ
                                    </Button>
                                </Upload>
                            </FieldBlock>
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        );
    }

    const passportHomePageDisabled = getFieldDisabled("scanPassportHomePage"),
        passportRegPageDisabled = getFieldDisabled("scanPassportRegPage"),
        scanDriverLicense1Disabled = getFieldDisabled("scanDriverLicense1"),
        scanDriverLicense2Disabled = getFieldDisabled("scanDriverLicense2");

    return (
        <Spin spinning={loading} tip="Загрузка...">
            <Row gutter={[16, 24]}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <InputField
                        name="fio" label="ФИО"
                        value={fio} setValue={setFio}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                fio: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                fio: e.target.value
                            });
                        }}
                    />
                </Col>
                {/*<Col lg={6} md={12} sm={12} xs={12}>*/}
                {/*    <InputField*/}
                {/*        name="phone" label="Телефон"*/}
                {/*        value={phone} setValue={setPhone}*/}
                {/*        getFieldErrors={getFieldErrors}*/}
                {/*        getFieldDisabled={getFieldDisabled}*/}
                {/*        getDefaultData={getDefaultData}*/}
                {/*        onInputChange={(e) => {*/}
                {/*            setDefaultData({*/}
                {/*                ...defaultData,*/}
                {/*                phone: e.target.value*/}
                {/*            });*/}
                {/*            setValuesInvalidFields({*/}
                {/*                ...valuesInvalidFields,*/}
                {/*                phone: e.target.value*/}
                {/*            });*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col lg={6} md={12} sm={12} xs={12}>
                    <SelectField
                        title="Страна регистрации"
                        name="regCountry"
                        selectedValue={regCountry}
                        values={[
                            { Id: 'Россия', Name: 'Россия' },
                            { Id: 'Беларусь', Name: 'Беларусь' },
                            { Id: 'Казахстан', Name: 'Казахстан' },
                            { Id: 'Другая страна', Name: 'Другая страна' }
                        ]}
                        onChange={(value) => {
                            setRegCountry(value);
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                regCountry: value
                            });
                        }}
                        getErrors={getFieldErrors}
                        style={{ width: "100%" }}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={12} md={24} sm={24} xs={24} span={12}>
                    <PhoneField
                        getErrors={getFieldErrors}
                        countryFieldName="phoneCountryID"
                        phoneFieldName="phone"
                        title="Моб. номер"
                        selectedCountryID={phoneCountryID}
                        onChangeCountry={(value) => {
                            setPhoneCountryID(value);
                            setDefaultData({
                                ...defaultData,
                                phoneCountryID: value,
                            });
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                phoneCountryID: value,
                            });
                        }}
                        phoneNumber={phone}
                        onChangePhone={(e) => {
                            setPhone(e.target.value);
                            setDefaultData({
                                ...defaultData,
                                phone: e.target.value,
                            });
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                phone: e.target.value,
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <InputField
                        name="additionalPhoneOne" label="Доп. номер"
                        value={additionalPhoneOne} setValue={setAdditionalPhoneOne}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                appViber: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                appViber: e.target.value
                            });
                        }}
                    />
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <InputField
                        name="additionalPhoneTwo" label="Доп. номер"
                        value={additionalPhoneTwo} setValue={setAdditionalPhoneTwo}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                appWhatsapp: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                appWhatsapp: e.target.value
                            });
                        }}
                    />
                </Col>
                {regCountry === 'Россия' ? <Col lg={6} md={12} sm={12} xs={12}>
                    <InputField
                        name="passportSerial" label="Серия паспорта"
                        value={serialPassport} setValue={setSerialPassport}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                passportSerial: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                passportSerial: e.target.value
                            });
                        }}
                    />
                </Col> : null}
                <Col lg={regCountry === 'Россия' ? 6 : 12} md={12} sm={12} xs={12}>
                    <InputField
                        name="passportNumber" label="Номер паспорта"
                        value={numberPassport} setValue={setNumberPassport}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                passportNumber: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                passportNumber: e.target.value
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <InputField
                        name="passportDivision" label="Подразделение"
                        value={divisionPassport} setValue={setDivisionPassport}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                passportDivision: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                passportDivision: e.target.value
                            });
                        }}
                    />
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <FieldBlock title="Дата выдачи" name="passportDate" getErrors={getFieldErrors} getFieldDisabled={getFieldDisabled}>
                        <DatePicker
                            style={{ width: "100%" }}
                            defaultValue={datePassport}
                            value={datePassport}
                            format="DD.MM.YYYY"
                            size="normal"
                            name="passportDate"
                            locale={localeDatePicker}
                            allowClear={false}
                            onChange={(date) => {
                                setDatePassport(date);
                                setValuesInvalidFields({
                                    ...valuesInvalidFields,
                                    passportDate: date ? date.format('YYYY-MM-DD') : date
                                });
                            }}
                        />
                    </FieldBlock>
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <InputField
                        name="registration" label="Прописка (страна, город, улица, дом, кв)"
                        value={registrationPassport} setValue={setRegistrationPassport}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                registration: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                registration: e.target.value
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <InputField
                        name="driverLicenseSerial" label="Серия ВУ"
                        value={serialDriverLicense} setValue={setSerialDriverLicense}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                driverLicenseSerial: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                driverLicenseSerial: e.target.value
                            });
                        }}
                    />
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <InputField
                        name="driverLicenseNumber" label="Номер ВУ"
                        value={numberDriverLicense} setValue={setNumberDriverLicense}
                        getFieldErrors={getFieldErrors}
                        getFieldDisabled={getFieldDisabled}
                        getDefaultData={getDefaultData}
                        onInputChange={(e) => {
                            setDefaultData({
                                ...defaultData,
                                driverLicenseNumber: e.target.value
                            })
                            setValuesInvalidFields({
                                ...valuesInvalidFields,
                                driverLicenseNumber: e.target.value
                            });
                        }}
                    />
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <FieldBlock title="Дата выдачи ВУ" name="driverLicenseDate" getErrors={getFieldErrors} getFieldDisabled={getFieldDisabled}>
                        <DatePicker
                            style={{ width: "100%" }}
                            defaultValue={dateDriverLicense}
                            value={dateDriverLicense}
                            format="DD.MM.YYYY"
                            size="normal"
                            name="driverLicenseDate"
                            locale={localeDatePicker}
                            allowClear={false}
                            onChange={(date) => {
                                setDateDriverLicense(date);
                                setValuesInvalidFields({
                                    ...valuesInvalidFields,
                                    driverLicenseDate: date ? date.format('YYYY-MM-DD') : date
                                });
                            }}
                        />
                    </FieldBlock>
                </Col>
                {/*<Col lg={12} md={24} sm={24} xs={24}>*/}
                {/*    <FieldBlock name="readTermsOfUse" getErrors={getFieldErrors}>*/}
                {/*        <Checkbox*/}
                {/*            name="readTermsOfUse"*/}
                {/*            checked={readTermsOfUse}*/}
                {/*            onChange={(e) => {*/}
                {/*                setReadTermsOfUse(e.target.checked);*/}
                {/*                setValuesInvalidFields({*/}
                {/*                    ...valuesInvalidFields,*/}
                {/*                    readTermsOfUse: e.target.checked ? 1 : 0*/}
                {/*                });*/}
                {/*            }}*/}
                {/*            style={{fontSize: "10pt", paddingTop: "15px"}}*/}
                {/*        >*/}
                {/*            Водитель ознакомлен с <span onClick={handleOpenTermsOfUse}>пользовательским соглашением</span>*/}
                {/*        </Checkbox>*/}
                {/*    </FieldBlock>*/}
                {/*</Col>*/}
            </Row>

            <span className="divider-label">Загрузите сканы документов</span>
            <DividerBlock style={dividerStyle} />

            <Row gutter={[16, 24]}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <FieldBlock name="scanPassportHomePage" getErrors={getFieldErrors} getDefaultData={getDefaultData}>
                        <Upload className={`single-btn-upload ${passportHomePageDisabled ? 'anticon-none' : ''}`} {...uploaderPropsScanHomePagePassport} disabled={passportHomePageDisabled} onPreview={handlePreview}>
                            <Button htmlType="button" disabled={passportHomePageDisabled}>
                                <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                    <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                    <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                    <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                </svg> Скан главной страницы паспорта
                            </Button>
                        </Upload>
                    </FieldBlock>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <FieldBlock name="scanPassportRegPage" getErrors={getFieldErrors} getDefaultData={getDefaultData}>
                        <Upload className={`single-btn-upload ${passportRegPageDisabled ? 'anticon-none' : ''}`} {...uploaderPropsScanRegPagePassport} disabled={passportRegPageDisabled} onPreview={handlePreview}>
                            <Button htmlType="button" disabled={passportRegPageDisabled}>
                                <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                    <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                    <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                    <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                </svg> Скан страницы с пропиской
                            </Button>
                        </Upload>
                    </FieldBlock>
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <FieldBlock name="scanDriverLicense1" getErrors={getFieldErrors} getDefaultData={getDefaultData}>
                        <Upload className={`single-btn-upload ${scanDriverLicense1Disabled ? 'anticon-none' : ''}`} {...uploaderPropsScanDriverLicense1} disabled={scanDriverLicense1Disabled} onPreview={handlePreview}>
                            <Button htmlType="button" disabled={scanDriverLicense1Disabled}>
                                <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                    <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                    <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                    <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                </svg> Скан 1 стороны ВУ
                            </Button>
                        </Upload>
                    </FieldBlock>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <FieldBlock name="scanDriverLicense2" getErrors={getFieldErrors} getDefaultData={getDefaultData}>
                        <Upload className={`single-btn-upload ${scanDriverLicense2Disabled ? 'anticon-none' : ''}`} {...uploaderPropsScanDriverLicense2} disabled={scanDriverLicense2Disabled} onPreview={handlePreview}>
                            <Button htmlType="button" disabled={scanDriverLicense2Disabled}>
                                <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="download-file-svg">
                                    <path d="M11.5631 0.165835C11.4647 0.0622087 11.3298 0 11.19 0H2.81953C1.27495 0 0 1.26988 0 2.81429V18.643C0 20.1875 1.27495 21.4574 2.81953 21.4574H14.1805C15.725 21.4574 17 20.1875 17 18.643V6.07435C17 5.93962 16.9378 5.81013 16.8497 5.71158L11.5631 0.165835ZM11.7134 1.82959L15.2534 5.54574H12.9522C12.268 5.54574 11.7134 4.99634 11.7134 4.31221V1.82959ZM14.1805 20.4208H2.81953C1.85038 20.4208 1.03659 19.6175 1.03659 18.643V2.81429C1.03659 1.84514 1.84514 1.03659 2.81953 1.03659H10.6768V4.31221C10.6768 5.57161 11.6928 6.58233 12.9522 6.58233H15.9634V18.643C15.9634 19.6175 15.1549 20.4208 14.1805 20.4208Z" fill="#1CAA53"></path>
                                    <path d="M12.7642 16.8447H4.23314C3.94813 16.8447 3.71484 17.0778 3.71484 17.363C3.71484 17.648 3.94813 17.8813 4.23314 17.8813H12.7695C13.0545 17.8813 13.2878 17.648 13.2878 17.363C13.2878 17.0778 13.0545 16.8447 12.7642 16.8447Z" fill="#1CAA53"></path>
                                    <path d="M5.84741 11.268L7.9828 8.97187V14.6317C7.9828 14.9167 8.21608 15.15 8.5011 15.15C8.78627 15.15 9.01939 14.9167 9.01939 14.6317V8.97187L11.1548 11.268C11.2584 11.3767 11.3933 11.4339 11.5331 11.4339C11.6575 11.4339 11.7872 11.3872 11.8856 11.2939C12.093 11.097 12.1085 10.7704 11.9116 10.5631L8.87435 7.30304C8.7758 7.19942 8.64107 7.13721 8.49602 7.13721C8.35081 7.13721 8.21608 7.19942 8.11769 7.30304L5.08044 10.5631C4.8835 10.7704 4.89906 11.102 5.10631 11.2939C5.32404 11.4908 5.65047 11.4753 5.84741 11.268Z" fill="#1CAA53"></path>
                                </svg> Скан 2 стороны ВУ
                            </Button>
                        </Upload>
                    </FieldBlock>
                </Col>
            </Row>
            <Modal destroyOnClose={true} visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="Изображение" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </Spin>);
}
