import React, {useEffect, useState} from "react";
import {deliveriesService, orderDeliveryService, userServices} from "../../../services";
import {Col, Modal, notification, Row, Spin, Popconfirm, Icon, Button, Table, Divider, Descriptions} from "antd";
import moment from "moment";
import '../modals.css';
import {FormatPrice, FullDate} from "../../tools/delivery-tools";
import { DeliveryOfferInfo } from "../create-delivery-offer";

function ApprovedBtn({
                       onAgreeOffer,
                       onCancelAgreeOffer,
                       orderDeliveryId,
                       creatorRole,
                       status,
                       setLoading,
                       user,
                       isAnySumApprove,
                       minOrdersToApprove,
                       setNeedUpdateContact,
                       ordersCount,
                       hasAgreed,
                       dateLoading,
                       orderItem,
                       accessToApproveOrders
                     }) {
  const [showModalCounteroffer, setShowModalCounteroffer] = useState(false);
  const onConfirmApproveOrderDelivery = (e) => {
    onAgreeOffer();
  };

  let buttons = [];

  if(accessToApproveOrders) {
    if(status === 'new' && (!hasAgreed)) {
      buttons.push(
        <Button
          disabled={false}
          htmlType="button" className="btn typical-btn typical-btn-green typical-btn-xs"
          style={{width: '100%'}}
          onClick={onAgreeOffer}>
          Утвердить
        </Button>
      );
    } else if(status === 'refused') {
      buttons.push(
        <div className="info-line--desc red">Отменён</div>
      );
    }else if(status === 'agreed') {
      buttons.push(
        <div className="info-line--desc green">Принят</div>
      );
      buttons.push(
        <Button
          disabled={false}
          htmlType="button" className="btn typical-btn typical-btn-red typical-btn-xs"
          style={{width: '100%'}}
          onClick={onCancelAgreeOffer}>
          Отменить
        </Button>
      );
    }
  }

  return (
    <div style={{textAlign: 'center'}}>
      {buttons}
    </div>
  );
  
}

function TableHeaders() {
  return (
    <thead>
    <tr>
      <th>Дата заявки</th>
      <th>Перевозчик</th>
      <th>Ответственный</th>
      <th>Телефон</th>
      {<th>Дата загрузки</th>}
      <th>Ставка</th>
      <th/>
    </tr>
    </thead>
  );
}

function ContentModalListOffers({
                                  setErrorMsg,
                                  ordersData,
                                  deliveryData,
                                  loading,
                                  errorMsg,
                                  setLoading,
                                  setNeedUpdateDeliveryList,
                                  setStopListUpdate,
                                  setIsShow,
                                  setNeedUpdateContact,
                                  isShow,
                                  user
                                }) {
  if (errorMsg) {
    return (<div className="content-error-block">{errorMsg}</div>);
  }
  if (loading) {
    return (
      <Row gutter={16} className="add-padding-10">
        <Col span={24} style={{textAlign: 'center'}}>
          <Spin spinning={loading} tip="Загрузка..."/>
        </Col>
      </Row>
    );
  }

  const ordersCount = ordersData.OffersCount;
  const hasAgreed = ordersData.HasAgreed;

  const ActionsButtons = ({orderItem}) => {
    const [orderLimit, setOrderLimit] = useState(null);
    const [orderStatus, setOrderStatus] = useState(orderItem.Status);
    const agreeOffer = () => {

      setLoading(true);

      deliveriesService.agreeOffer(deliveryData.Id, orderItem.Id)
        .then((response) => {
          notification.success({
            message: "Одобрение заявки",
            description: response.message,
          });

          setLoading(false);
          setIsShow(false);
          // setNeedUpdateDeliveryList(true);
          // setStopListUpdate(false);
        })
        .catch((error) => {
          notification.error({
            message: "Одобрение заявки",
            description: error,
          });

          setLoading(false);
        });
    };

    const cancelAgreeOffer = () => {

      setLoading(true);

      deliveriesService.cancelAgreeOffer(deliveryData.Id, orderItem.Id)
        .then((response) => {
          notification.success({
            message: "Отмена одобрения заявки",
            description: response.message,
          });

          setLoading(false);
          setIsShow(false);
        })
        .catch((error) => {
          notification.error({
            message: "Отмена одобрения заявки",
            description: error,
          });

          setLoading(false);
        });
    };

      return (
          <ApprovedBtn
              onAgreeOffer={agreeOffer}
              onCancelAgreeOffer={cancelAgreeOffer}
              orderDeliveryId={orderItem.Id}
              creatorRole={orderItem.CreatorRole}
              setLoading={setOrderStatus}
              setNeedUpdateContact={setNeedUpdateContact}
              status={orderStatus}
              dateLoading={orderItem.Date}
              user={user}
              isAnySumApprove={orderItem.IsAnySumApprove}
              minOrdersToApprove={orderItem.MinOrdersToApprove}
              ordersCount={ordersCount}
              hasAgreed={hasAgreed}
              orderItem={orderItem}
              accessToApproveOrders={ordersData.Permissions.IsAccessToApproveOrders}
          />
      );
  }

  const columns = [
    {
      render: (record) => (
        <div style={{padding: "10px 0"}}>
          <Descriptions size="small"
                        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
                        layout="vertical"
          >
            <Descriptions.Item
              span="2"
              label={<b>Перевозчик</b>}
            >{(record.Company && record.Company.Contact && record.Company.LegalEntity.Name)
              ? record.Company.LegalEntity.Name
              : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Ответственный</b>}>{(record.Company && record.Company.Contact && record.Company.Contact.Fio)
              ? record.Company.Contact.Fio
              : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Телефон</b>}>{(record.Company && record.Company.Contact && record.Company.Contact.Phone)
              ? record.Company.Contact.Phone
              : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label={<b>Дата заявки</b>}>{moment(record.DateCreated).format('D.MM.YYYY HH:mm')}</Descriptions.Item>
            <Descriptions.Item label={<b>Дата загрузки</b>}>{moment(record.Date).format('D.MM.YYYY')}</Descriptions.Item>
            <Descriptions.Item label={<b>Ставка</b>}>
              <div className="order-price-block">
                <div>{(record.Price).toLocaleString('ru')}</div>
                <span
                  className="payment-type">{record.paymentType === 'null-payment-type' ? '-' : record.PaymentType}</span>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      ),
      className: "desktop-hidden--md"
    },
    {
      title: "Дата заявки",
      render: (record) => (
        <React.Fragment>
          {moment(record.DateCreated).format('D.MM.YYYY HH:mm')}
        </React.Fragment>
      ),
      className: "mobile-hidden--md",
      rowSpan: 2,
    },
    {
      title: 'Перевозчик',
      render: (record) => (
        <>{(record.Company && record.Company.Contact && record.Company.LegalEntity.Name)
          ? record.Company.LegalEntity.Name
          : '-'}</>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: <>Ответственный/<br/>Телефон</>,
      render: (record) => (
        <>
          <div>
            {(record.Company && record.Company.Contact && record.Company.Contact.Fio)
              ? record.Company.Contact.Fio
              : '-'}
          </div>
          <div>
            <b>{(record.Company && record.Company.Contact && record.Company.Contact.Phone)
              ? record.Company.Contact.Phone
              : '-'}</b>
          </div>
        </>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: 'Дата загрузки',
      render: (record) => (
        <>{moment(record.Date).format('D.MM.YYYY')}</>
      ),
      className: "mobile-hidden--md"
    },
    {
      title: "Ставка",
      render: (record) => (
        <div className="order-price-block">
          <div>{(record.Price).toLocaleString('ru')}</div>
          <span className="payment-type">{record.paymentType === 'null-payment-type' ? '-' : record.PaymentType}</span>
        </div>
      ),
      className: "mobile-hidden--md"
    },
    {
      render: (record) => <ActionsButtons orderItem={record}/>,
      //className: "mobile-hidden--md"
    }
  ];

  return (
    <>
      <DeliveryOfferInfo delivery={deliveryData}/>
      <Table
        columns={columns}
        dataSource={ordersData.Offers}
        defaultExpandAllRows={true}
        expandedRowRender={record => <p style={{ margin: 0 }}>{record.Comment}</p>}
        className={"employees-table"}
        rowKey={"id"}
        size="small"
        pagination={{pageSize: 100}}
      />
    </>
  );
}

export default function ModalListOffersDelivery({
                                                  isShow,
                                                  setIsShow,
                                                  deliveryId,
                                                  setNeedUpdateDeliveryList,
                                                  setStopListUpdate,
                                                  delivery
                                                }) {
  const [loading, setLoading] = useState(false);
  const [loadingContentModal, setLoadingContentModal] = useState(false);
  const [ordersData, setOrdersData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const user = userServices.getUser();
  const [needUpdateContact, setNeedUpdateContact] = useState(false);

  useEffect(() => {
    if (isShow && !loadingContentModal) {
      setLoading(true);
      setLoadingContentModal(true);
    }
  }, [isShow]);

  if (loadingContentModal) {
    deliveriesService.getListNewOffersDelivery(deliveryId)
      .then((response) => {
        setLoadingContentModal(false);
        setOrdersData(response);
        setLoading(false);
      })
      .catch((errorMsg) => {
        setErrorMsg(errorMsg);
      })
  }

  useEffect(() => {
    if (needUpdateContact) {
      deliveriesService.getListNewOffersDelivery(deliveryId)
        .then((response) => {
          setLoadingContentModal(false);
          setOrdersData(response);
          setLoading(false);
          setNeedUpdateContact(false)
        })
        .catch((errorMsg) => {
          setErrorMsg(errorMsg);
        })
    }
  }, [needUpdateContact])

  return (
    <Modal
      title="Полученные запросы"
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
        // setNeedUpdateDeliveryList(false);
        // setStopListUpdate(false);
      }}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <ContentModalListOffers
            loading={loading}
            errorMsg={errorMsg}
            ordersData={ordersData}
            deliveryData={delivery}
            //setStopListUpdate={setStopListUpdate}
            setLoading={setLoading}
            setNeedUpdateContact={setNeedUpdateContact}
            setErrorMsg={setErrorMsg}
            setIsShow={setIsShow}
            // setNeedUpdateDeliveryList={setNeedUpdateDeliveryList}
            isShow={isShow}
            user={user}
          />
        </Col>
      </Row>
    </Modal>
  );
}
