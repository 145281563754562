import React from "react";
import DividerBlock from "../../../../divider-block";
import FieldBlock from '../../../../field-block';
import { DatePicker, TimePicker } from "antd";
import { Col, Row } from "antd";
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import NumberInputField from "../../../../number-input-field";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function Reduction({
  tenderTimeStart,
  tenderTimeEnd,
  setLoadStart,
  setLoadEnd,
  setTenderStart,
  setTenderEnd,
  setTenderTimeStart,
  setTenderTimeEnd,
  getFieldErrors,
  maxPrice,
  setMaxPrice,
  stepPrice,
  setStepPrice,
  dividerStyle}
) {
    return (
      <>
      <span className="divider-label">Параметры редукциона</span>
      <DividerBlock style={dividerStyle} />
      <Row gutter={[16, 24]} >
        <Col lg={12} md={24} sm={24} sx={24}>
          <FieldBlock  name="tenderStart" title="Период проведения редукциона (МСК)" getErrors={getFieldErrors}>
            <RangePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              locale={localeDatePicker}
              onChange={(date) => {
                  setTenderStart(date[0]);
                  setTenderEnd(date[1]);
              }}
            />
            <TimePicker 
              defaultValue={tenderTimeStart}
              placeholder="Время начала"
              format="HH:mm"
              onChange={(time, timeString) => {setTenderTimeStart(time)}}
              style={{ width: "50%" }} 
            />
            <TimePicker 
              placeholder="Время окончания"
              defaultValue={tenderTimeEnd}
              format="HH:mm"
              onChange={(time, timeString) => {setTenderTimeEnd(time)}}
              style={{ width: "50%" }} 
            />
          </FieldBlock>
        </Col>
      </Row>
      <Row gutter={[16, 24]} >
        <Col lg={12} md={24} sm={24} sx={24}>
          <NumberInputField
            name="maxPrice" label="Начальная ставка (без учета НДС)"
            value={maxPrice} setValue={setMaxPrice}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col lg={12} md={24} sm={24} sx={24}>
          <NumberInputField
            name="stepPrice" label="Минимальный шаг понижения"
            value={stepPrice} setValue={setStepPrice}
            getFieldErrors={getFieldErrors}
          />
        </Col>
      </Row>
      </>
    );
}