import React, {useEffect,useState} from 'react';
import {Modal,notification,Row,Col,Button,Upload,Icon} from 'antd';
import {companyService} from '../../../services';
import FieldBlock from '../../field-block';
import Spinner from "../../spinner";
import {formHelper} from '../../../helpers/form.helper';
import '../modals.css';
import './buttons.css';
import EditLithuaniaLegalEntityDocs from "./lithuania-legal-entity";
import EditBelarusLegalEntityDocs from "./belarus-legal-entity";
import EditKazakhstanLegalEntityDocs from "./kazakhstan-legal-entity";
import EditRusLegalEntityDocs from "./rus-legal-entity";

export default function ModalEditLegalEntityDocs({isShow, setIsShow, legalEntityId, orgForm}) {

    const [docFields, setDocFields] = useState([]);
    const [legalEntityData, setLegalEntityData] = useState({});
    const [countryId, setCountryId] = useState(null);

    const [isNeedPowerOfAttorneyForAPerson, setIsNeedPowerOfAttorneyForAPerson] = useState(false);
    const [isNDSPayer, setIsNDSPayer] = useState(false);

    const [fieldsErrors, setFieldErrors] = useState({});
    const [loadingContentModal, setLoadingContentModal] = useState(false);

    useEffect(() => {
        if (isShow && legalEntityId) {
            setLoadingContentModal(true);
            companyService
                .getLegalEntityDocs(legalEntityId)
                .then(resp => {
                    if (resp.code !== 400) {
                        let fileListArr = [];

                        for (let doc in resp.Docs) {
                            let contentType = 'image/jpeg'
                            if (resp.Docs[doc].Extension === 'pdf') {
                                contentType = 'application/pdf';
                            }

                            let blob = formHelper.b64toBlob(resp.Docs[doc].File, contentType);

                            let file = new File([blob], 'Документ.'+ resp.Docs[doc].Extension);
                            file.uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                            file.url = URL.createObjectURL(blob);
                            if (resp.Docs[doc].Extension === 'pdf') {
                                file.thumbUrl = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
                            }

                            fileListArr[doc] = [file];
                        }

                        setDocFields(fileListArr);
                        setFieldErrors({});

                        setLegalEntityData(resp.LegalEntityData);
                        setCountryId(resp.LegalEntityData.CountryId);
                    } else {
                        notification.error({
                            title: "Получение документов юр. лица",
                            descriptor: resp.message
                        });
                    }
                })
                .catch(errMsg =>
                    notification.error({
                        title: "Получение документов юр. лица",
                        descriptor: errMsg
                    })
                )
                .finally(() => setLoadingContentModal(false))
            ;
            
        }
    }, [legalEntityId, isShow]);

    const bigButtonStyle = { width: "230px", height: "50px", fontSize: "24px", color: "white", backgroundColor: "#1CAA53", border: "none" };

    const handleUpdateDocs = () => {
        setLoadingContentModal(true);

        companyService
            .editLegalEntityDocs(docFields, orgForm, legalEntityId, countryId, isNeedPowerOfAttorneyForAPerson, isNDSPayer)
            .then(resp => {
                if (resp.code === 200) {
                    notification.success({
                        message: "Сохранение документов юр. лица",
                        description: resp.message});
                    setIsShow(false);
                } else {
                    notification.error({
                        message: "Сохранение документов юр. лица",
                        description: resp.message});
                    if (resp.errors) setFieldErrors(resp.errors);
                }
                setLoadingContentModal(false);
            })
            .catch(errMsg => notification.error({
                message: "Сохранение данных юр. лица",
                description: errMsg
            }));
    };

    const renderDocsForm = () => {
        switch (countryId) {
            case 1:
                return (
                    <EditRusLegalEntityDocs
                        setIsShow={setIsShow}
                        legalEntityId={legalEntityId}
                        legalEntityData={legalEntityData}
                        docFields={docFields}
                        setDocFields={setDocFields}
                        fieldsErrors={fieldsErrors}
                        isNeedPowerOfAttorneyForAPerson={isNeedPowerOfAttorneyForAPerson}
                        setIsNeedPowerOfAttorneyForAPerson={setIsNeedPowerOfAttorneyForAPerson}
                        isNDSPayer={isNDSPayer}
                        setIsNDSPayer={setIsNDSPayer}
                        isConsignor={legalEntityData.PurposeType === 'consignor_purpose_type'}
                    />
                );
            case 7:
                return (
                    <EditLithuaniaLegalEntityDocs
                        setIsShow={setIsShow}
                        legalEntityId={legalEntityId}
                        legalEntityData={legalEntityData}
                        docFields={docFields}
                        setDocFields={setDocFields}
                        fieldsErrors={fieldsErrors}
                    />
                );

            case 3:
                return (
                    <EditBelarusLegalEntityDocs
                        setIsShow={setIsShow}
                        legalEntityId={legalEntityId}
                        legalEntityData={legalEntityData}
                        docFields={docFields}
                        setDocFields={setDocFields}
                        fieldsErrors={fieldsErrors}
                    />
                );
            case 6:
                return (
                    <EditKazakhstanLegalEntityDocs
                        setIsShow={setIsShow}
                        legalEntityId={legalEntityId}
                        legalEntityData={legalEntityData}
                        docFields={docFields}
                        setDocFields={setDocFields}
                        fieldsErrors={fieldsErrors}
                        isProprietor={legalEntityData.OrgFormId === 27}
                        isNeedPowerOfAttorneyForAPerson={legalEntityData.LegalReason === 'Устав'}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            title={"Редактирование документов Юр. лица"}
            cancelText="Закрыть"
            className="modal-window"
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={
                <div style={{marginTop: "10"}}>
                    <Button onClick={handleUpdateDocs} htmlType="submit" key="submit" style={bigButtonStyle} type="primary">
                        Сохранить
                    </Button>
                </div>
            }
        >
            <Spinner loading={loadingContentModal} tip="Загрузка...">
                {renderDocsForm()}
            </Spinner>
        </Modal>
    );
}
