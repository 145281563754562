import React, { useState, useEffect } from "react";
import {Col, Row, DatePicker, Checkbox} from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import InputField from '../../../../input-field';
import NumberInputField from '../../../../number-input-field';
import SelectField from '../../../../select-field';
import FieldBlock from '../../../../field-block';
import * as moment from 'moment';

export default function FirstStep({ stepFields, setFields, getFieldErrors, organisationFormsList, countryList, taxTypes, reindexedTaxTypes, edit = false }) {
    const ndsList = [
        {
            Id: 1,
            Name: 'С НДС'
        },
        {
            Id: 2,
            Name: 'Без НДС'
        }
    ];

    const [notRegisteredATI, setNotRegisteredATI] = useState(false);
    const [taxId, setTaxId] = useState(stepFields.taxId);

    useEffect(() => {
        if (stepFields.workWithNds === 2) {
            setFields({...stepFields, 
                taxPercent: 0, 
                taxType: 'no_nds'
            });
            setTaxId(null);
        } 
    }, [stepFields.workWithNds]);

    return (
        <>
            <Row>
                <Col lg={8} sm={24}>
                    <InputField
                        name="name"
                        label="Название"
                        required={true}
                        value={stepFields.name}
                        setValue={(value) => {setFields({...stepFields, name: value});}}
                        getFieldErrors={getFieldErrors}
                    />
                </Col>
                <Col lg={8} sm={24}>
                    <FieldBlock name="dateRegistration" title="Дата регистрации юр. лица" getErrors={getFieldErrors}>
                        <DatePicker
                            style={{ width: "100%" }}
                            value={stepFields.dateRegistration}
                            defaultValue={stepFields.dateRegistration}
                            format="DD.MM.YYYY"
                            size="large"
                            name="dateRegistration"
                            locale={localeDatePicker}
                            onChange={value => setFields({...stepFields, dateRegistration:  value})}
                        />
                    </FieldBlock>
                </Col>
            </Row>
            <Row>
                <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField name="inn" label="ИНН" value={stepFields.inn} setValue={value => setFields({...stepFields, inn: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                </Col>
                { stepFields.orgFormId === 1 ? (
                    <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                        <InputField name="ogrnip" label="ОГРНИП"  value={stepFields.ogrnip} setValue={value => setFields({...stepFields, ogrnip: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                    </Col>
                ) : (
                    <>
                        <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                            <InputField name="kpp" label="КПП" value={stepFields.kpp} setValue={value => setFields({...stepFields, kpp: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                        </Col>
                        <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                            <InputField name="ogrn" label="ОГРН" value={stepFields.ogrn} setValue={value => setFields({...stepFields, ogrn: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                        </Col>
                    </>
                )}
                <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField name="okpo" label="ОКПО" value={stepFields.okpo} setValue={value => setFields({...stepFields, okpo: value.replace(/[^0-9]/g, '')})} getFieldErrors={getFieldErrors} />
                </Col>
                <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <SelectField
                        title="НДС"
                        name="legalReason"
                        selectedValue={stepFields.workWithNds}
                        values={ndsList}
                        onChange={(value) => {
                            setFields({
                                ...stepFields,
                                workWithNds: value,
                            });
                        }}
                        getErrors={getFieldErrors}
                        placeholder="Укажите основание действий"
                        notFoundText={null}
                        showArrow={true}
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col lg={8} sm={24} >
                    {(stepFields.workWithNds === 1) && <SelectField
                        title="Тип ндс"
                        name="tax"
                        selectedValue={taxId}
                        values={taxTypes}
                        getErrors={getFieldErrors}
                        onChange={(value) => {
                            setTaxId(value);
                            setFields({...stepFields, 
                                taxPercent: reindexedTaxTypes[value].percent, 
                                taxType: reindexedTaxTypes[value].type
                            });
                        }}
                        placeholder="Выберите тип"
                        notFoundText="Отсутствуют типы"
                        style={{ width: "100%" }}
                    />}
                </Col>
            </Row>
            <Row>
                <Col lg={8} sm={24} style={{ margin: "0 0 20px 0" }}>
                    <InputField
                        name="atiCode"
                        label="Код АТИ"
                        value={stepFields.atiCode}
                        isDisabled={notRegisteredATI}
                        setValue={value => setFields({...stepFields, atiCode: value})}
                        getFieldErrors={getFieldErrors}
                    />
                    <Checkbox
                        name="accreditedIntDeliveries"
                        checked={notRegisteredATI}
                        onChange={(e) => {
                            setFields({
                                ...stepFields,
                                atiCode: e.target.checked ? 'Без АТИ' : ''
                            });
                            setNotRegisteredATI(e.target.checked)
                        }}
                        style={{fontSize: "10pt", paddingTop: "15px"}}
                    >
                        Не зарегистрирован на АТИ
                    </Checkbox>
                </Col>
            </Row>
        </>
    );
}
