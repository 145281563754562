import React from "react";
import moment from "moment";
import {userServices} from "../../services";
import {
    DELIVERY_AGREED,
    DELIVERY_AWAITING_LOADING, DELIVERY_BOOKED,
    DELIVERY_ENROUTE,
    DELIVERY_LOADING
} from "../../helpers/delivery_status.helper";

export function Directions({ images }) {
    const names = {
        'scheme': 'Схема проезда',
        'photo': 'Фотография',
    }

    if (images && images.length > 0) {
        return images.map((image, idx) => {
            var name = names[image.type]
            return <p key={idx}><a target="_blank" href={image.file}>{name}</a></p>;
        })
    } else {
        return <div />;
    }
}

export function CommentsBlock({ comments }) {
    if (comments) {
        return (
            <div className="info-line">
                <div className="info-line--title">Комментарий</div>
                <div className="info-line--desc red">{comments}</div>
            </div>
        );
    } else {
        return <div />;
    }
}

export function FormatPrice({price, showPrice, currencySymbol = '₽'}) {
    if(!showPrice || price === null || price === undefined || price == 0) {
        return (
            <div className="rs">
                -
            </div>
        );
    }

    return (
        <div className="rs">
            {price.toLocaleString("ru")} <span>{currencySymbol}</span>
        </div>
    );
}

export function CarrierInfo({delivery}) {
    const user = userServices.getUser();

    if(delivery.Status == DELIVERY_ENROUTE || delivery.Status == DELIVERY_AGREED
        || (user.info.userType == 'inner' && delivery.Status == DELIVERY_BOOKED && delivery.Order != null)) {

        const carrierCompanyPersonName = typeof delivery.Order.Company.Contact.Fio !== "undefined" ? delivery.Order.Company.Contact.Fio : "-";

        const carrierCompanyPersonPhone =
            typeof delivery.Order.Company.Contact.Phone !== "undefined" && delivery.Order.Company.Contact.Phone ? delivery.Order.Company.Contact.Phone : "-";

        const legalEntityName = delivery.Order.LegalEntity.Name !== null && delivery.Order.LegalEntity.Name ? delivery.Order.LegalEntity.Name : "-";

        const carrierDriverFio =
            delivery.Order.Company.Driver !== null && typeof delivery.Order.Company.Driver.Name !== "undefined" ? delivery.Order.Company.Driver.Name : "-";

        return (
            <div className="ens">
                <div className="info-line">
                    <div className="info-line--title">Юр.лицо перевозчика</div>
                    <div className="info-line--desc">{legalEntityName}</div>
                </div>
                <div className="info-line">
                    <div className="info-line--title">Отвественный</div>
                    <div className="info-line--desc">{carrierCompanyPersonName}</div>
                </div>
                <div className="info-line">
                    <div className="info-line--title">Телефон</div>
                    <div className="info-line--desc">{carrierCompanyPersonPhone}</div>
                </div>
            </div>
        );
    }

    return null;

}

export function LogistInfo({delivery}) {
    const user = userServices.getUser();

    if(user.info.userType != "inner") {
        return (
            <div className="ens">
                <div className="info-line">
                    <div className="info-line--title">Логист</div>
                    <div className="info-line--desc"><LogistName delivery={delivery} /></div>
                </div>
                <div className="info-line">
                    <div className="info-line--title">Телефон</div>
                    <div className="info-line--desc"><LogistPhone delivery={delivery} /></div>
                </div>
            </div>
        );
    }

    return null;
}

export function LogistName({delivery}) {
    return delivery.Logist ? delivery.Logist.Fio : "-";
}

export function LogistPhone({delivery}) {
    return delivery.Logist && delivery.Logist.Phone ? delivery.Logist.Phone : "-";
}

export function FullDate({date}) {
    if(date) {
        return moment(date).format("D MMMM YYYY");
    } else {
        return '-';
    }
}

export function DateTime({date}) {
    if(date) {
        return moment(date).format("DD.MM.YYYY HH:mm");
    } else {
        return '-';
    }
}

export function ShortDate({date}) {
    return moment(date).format("DD.MM.YYYY");
}

export function ShortPlaceUnload({delivery}) {
    const placesUnload = delivery.PlacesUnload.map((placeUnload, idx) => {
        if(idx < delivery.PlacesUnload.length - 1) {
            return <li title={placeUnload.City} key={idx + 1}>{placeUnload.City}</li>;
        } else {
            return "";
        }
    });

    return placesUnload;
}

export function FullPlacePoints({delivery, type}) {
    const placesUnload = delivery.PlacesUnload.map((placeUnload, idx) => {
        if (placeUnload.Type === type) {
            return <div className="info-line--desc" key={idx + 1}>{placeUnload.Address}</div>;
        }
        return null;
    });

    return placesUnload;
}
