import React, {useEffect, useState} from 'react';
import {Button, Col, notification, Popconfirm, Row, Table} from "antd";
import Spinner from "../../../spinner";
import {consignorService} from "../../../../services";
import ModalListChangesForApprove from "../../../modals/list-changes-for-approve";

export default function ActionsToApprove() {
  const [loading, setLoading] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [data, setData] = useState([]);
  const [showChangesModal, setShowChangesModal] = useState(null);

  useEffect(() => {
    if (needUpdate) {
      setLoading(true);
      consignorService
        .getActionsToApproveList()
        .then((resp) => {
          setData(resp);
        })
        .catch((err) => {
          notification.error({
            message: "Получение списка",
            description: err.message
          });
        })
        .finally(() => {
          setLoading(false);
          setNeedUpdate(false);
        });
    }
  }, [needUpdate]);

  const handleApprove = (id) => {
    setLoading(true);
    consignorService
      .approveAction(id)
      .then(resp => {
        notification.success({
          message: "Утверждение действия",
          description: resp.message,
        });
        setNeedUpdate(true);
      })
      .catch(err => {
        notification.error({
          message: "Утверждение действия",
          description: err
        });
        setLoading(false);
      });
  };

  const handleDecline = (id) => {
    setLoading(true);
    consignorService
      .declineAction(id)
      .then(resp => {
        notification.success({
          message: "Отмена действия",
          description: resp.message,
        });
        setNeedUpdate(true);
      })
      .catch(err => {
        notification.error({
          message: "Отмена действия",
          description: err
        });
        setLoading(false);
      });
  };

  const columns = [
    {
      title: 'Имя',
      width: '15%',
      render: (record) => {
        return <>
          <p>{record.userCreator.fio}</p>
          <p>{record.userCreator.companyLogin}</p>
        </>
      }
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
    },
    {
      title: 'Описание',
      key: 'description',
      width: '12%',
      render: (record) => {
        if (record.params.length > 0) {
          return <>
            <span>{record.description}</span>
            <Button
              key={record.id}
              htmlType="button"
              type="primary"
              className='btn typical-btn typical-btn-blue typical-btn-xs'
              onClick={() => setShowChangesModal(record.id)}
            >Посмотреть
            </Button>
            <ModalListChangesForApprove countryId={1} params={record.params} isShow={showChangesModal === record.id} setIsShow={setShowChangesModal}/>
          </>
        } else {
          return record.description;
        }
      }
    },
    {
      title: 'Дата запроса',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      width: '8%',
    },
    {
      title: '',
      key: 'approve',
      width: '8%',
      render: data => {
        return (
          <React.Fragment>
            <Popconfirm
              title='Вы уверены?'
              okText='Да'
              cancelText='Нет'
              onConfirm={() => {
                handleApprove(data.id);
              }}
            >
              <Button
                type="primary"
                htmlType="button"
                size="small"
                className="small-button typical-btn-green"
              >
                Утвердить
              </Button>
            </Popconfirm>
          </React.Fragment>
        );
      },
    },
    {
      title: '',
      key: 'decline',
      width: '8%',
      render: data => {
        return (
          <React.Fragment>
            <Popconfirm
              title='Вы уверены?'
              okText='Да'
              cancelText='Нет'
              onConfirm={() => {
                handleDecline(data.id);
              }}
            >
              <Button
                type="primary"
                htmlType="button"
                size="small"
                className="small-button typical-btn-red"
              >
                Отклонить
              </Button>
            </Popconfirm>
          </React.Fragment>
        );
      }
    },
  ];

  return (
    <React.Fragment>
      <h1 className="content-header">Запросы на подтверждение</h1>
      <Spinner loading={loading} tip="Загрузка...">
        <Table
          style={{minWidth: '700px'}}
          columns={columns}
          dataSource={data}
          locale={{emptyText: 'Данные отсутствуют'}}
          rowKey="id"
          size="small"
          pagination={{
            pageSize: 50
          }}
        />
      </Spinner>
    </React.Fragment>
  );
};
