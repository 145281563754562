import React, { useState, useEffect } from "react";
import { Spin, Radio, Button } from "antd";
import CountDownTimer from "../countdown-timer";
import { DELIVERY_FREE } from "../../helpers/delivery_status.helper";
import DeliveryItemContent from "./delivery-item-content";

export default function DeliveryItemBlock({
  content,
  header,
  setNeedUpdate,
  setStopListUpdate,
  isBooked = false,
  isReturnDeliveries = false,
  deliveryItemIndex = 0,
  deliveriesLength = 0,
  wasOpenedBeforeUpdate,
  setWasOpenedBeforeUpdate,
  permissions,
  ...props
}) {
  let applicationItemClassName;
  if (props.color && props.color.length) {
    applicationItemClassName = `application-item-${props.color}`;
  } else {
    applicationItemClassName = "";
  }

  if(props.delivery.Type && props.delivery.Type !== 'delivery') {
    applicationItemClassName = 'application-item-tender application-item-'+props.delivery.Type;
  }

  const [timerEnabled, setTimerEnabled] = useState(props.delivery.Status !== DELIVERY_FREE && props.delivery.secondsToAutoRefuse !== undefined);
  const [loadingDelivItem, setLoadingDelivItem] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [returnDelivery, setReturnDelivery] = useState({});

  let initMoment;
  if (props.delivery.secondsToAutoRefuse){
    initMoment = +new Date();
  }
  useEffect(() => {
    if (wasOpenedBeforeUpdate && wasOpenedBeforeUpdate[props.delivery.Id]) {
      setContentVisible(false);
    }
  }, [wasOpenedBeforeUpdate]);

  // const toggleContentVisible = (e) => {
  //   if (wasOpenedBeforeUpdate) {
  //     setWasOpenedBeforeUpdate({ ...wasOpenedBeforeUpdate, [props.delivery.Id]: contentVisible });
  //   }
  //   setContentVisible(!contentVisible);
  // };

  const nameRegionLastPointUnload = props.delivery.PlacesUnload.length
    ? props.delivery.PlacesUnload[props.delivery.PlacesUnload.length - 1].City
    : "-";

  const regionLoad = props.delivery.PlaceLoad.City ? props.delivery.PlaceLoad.City : "-";

  // const wasOpened = wasOpenedBeforeUpdate ? wasOpenedBeforeUpdate[props.delivery.Id] : false;

  return (
    <Spin spinning={loadingDelivItem} style={{ paddingTop: "100px", textAlign: "center" }} size="large" tip="Загрузка...">
      {isReturnDeliveries && Number(deliveryItemIndex) !== 0 &&
        <Radio className="radio-custom" name="returnDeliveryId"
               checked={props.choisenDeliveryId ? Boolean(props.delivery.Id === props.choisenDeliveryId) : false} value={props.delivery.Id}
               onClick={() => props.setReturnDelivery(props.delivery)}>
          Выбрать обратным рейсом
        </Radio>}
      <div className={`application-item ${applicationItemClassName} ${timerEnabled ? "block-with-timer" : ""}`}>
        {timerEnabled ? (
          <CountDownTimer
            secondsLeft={props.delivery.secondsToAutoRefuse}
            initMoment={initMoment}
            setTimerEnabled={setTimerEnabled}
            isBooked={isBooked}
          />
        ) : null}
        {DeliveryItemContent({ ...props, timerEnabled, setLoadingDelivItem, setStopListUpdate, setNeedUpdate, nameRegionLastPointUnload,  regionLoad, permissions, contentVisible, setContentVisible,  wasOpenedBeforeUpdate, setWasOpenedBeforeUpdate })}
      </div>
      {((isReturnDeliveries &&  Number(deliveryItemIndex) === Number(deliveriesLength)-1) || (isReturnDeliveries &&  Number(deliveryItemIndex) === 0)) &&
        <div className="order-button-wrapper">
          <Button type="button" onClick={props.onClickCreateOrderDelivery} disabled={!Boolean(props.choisenDeliveryId)}>
            Подать заявку на выбранные рейсы
          </Button>
        </div>}
    </Spin>
  );
}
