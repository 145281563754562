import React, { useEffect, useState } from 'react';

import { Row, Col, DatePicker, Button, notification, Input, Modal } from 'antd';
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';

import DividerBlock from "../../divider-block";
import FieldBlock from '../../field-block';
import InputField from "../../input-field";
import NumberInputField from "../../number-input-field";
import SelectField from "../../select-field";
import Spinner from '../../spinner';

import {
	consignorService,
	deliveriesService,
	distributionCenterService,
	systemService,
	trailService
} from "../../../services";
import CreateDelivery from "../../pages/consignor-page/create-delivery";


function PointUnload() {

	this.country = "";
	this.countryId = "";
	this.address = "";
	this.lat = "";
	this.lon = "";
	this.date = "";

}

export default function CreateDeliveryModal({ isShow, setIsShow, deliveryId, tenderToDelivery = false}) {

	// const { TextArea } = Input;
  //
	// const [loading, setLoading] = useState(false);
  //
	// const [shipperCompaniesList, getShipperCompaniesList] = useState([]);
	// const [shipperCompaniesListLoaded, getShipperCompaniesListLoaded] = useState(false);
  //
	// const [distributionCenterList, setDistributionCenterList] = useState([]);
	// const [distributionCenterListLoaded, setDistributionCenterListLoaded] = useState(false);
  //
	// const [countyList, setCountryList] = useState([]);
	// const [countyListLoaded, setCountryListLoaded] = useState(false);
  //
	// const [carcassTypes, setCarcassTypes] = useState([]);
	// const [carcassTypesLoaded, setCarcassTypesLoaded] = useState(false);
  //
	// const [packageTypesList, setPackageTypesList] = useState([
	// 	{ Id: 'Валом', Name: 'Валом' },
	// 	{ Id: 'На паллетах', Name: 'На паллетах' },
	// ]);
  //
	// const [loadingTypesList, setLoadingTypesList] = useState([
	// 	{ Id: 'Задняя', Name: 'Задняя' },
	// 	{ Id: 'Верхняя', Name: 'Верхняя' },
	// 	{ Id: 'Боковая', Name: 'Боковая' },
	// 	{ Id: 'Растентовка', Name: 'Растентовка' },
	// ]);
  //
	// let dividerStyle = { margin: '15px 0px 45px 0px', display: "block", clear: "both", width: "100%", minWidth: "100%", height: "1px" }
  //
	// useEffect(() => {
	// 	if (!shipperCompaniesListLoaded) {
	// 		consignorService.getShipperCompaniesList()
	// 			.then(res => {
	// 				getShipperCompaniesListLoaded(true);
	// 				getShipperCompaniesList(res);
	// 			})
	// 			.catch(errMsg => {
	// 				getShipperCompaniesListLoaded(true);
	// 				notification.error(
	// 					{
	// 						message: "Получение списка юр. лиц",
	// 						description: errMsg
	// 					});
	// 			})
	// 			;
	// 	}
	// }, []);
  //
	// useEffect(() => {
	// 	if (!distributionCenterListLoaded) {
	// 		distributionCenterService.getCompanies()
	// 			.then(res => {
	// 				setDistributionCenterListLoaded(true);
	// 				setDistributionCenterList(res);
	// 			})
	// 			.catch(errMsg => {
	// 				setDistributionCenterListLoaded(true);
	// 				notification.error(
	// 					{
	// 						message: "Получение списка распределительных центров",
	// 						description: errMsg
	// 					});
	// 			})
	// 			;
	// 	}
	// }, []);
  //
	// useEffect(() => {
	// 	if (!countyListLoaded) {
	// 		systemService
	// 			.getCountryList()
	// 			.then(response => {
	// 				setCountryList(response);
	// 				setCountryListLoaded(true);
	// 			})
	// 			.catch(errorMsg => {
	// 				notification.error({
	// 					message: "Получение списка стран",
	// 					description: errorMsg,
	// 				});
	// 			});
	// 	}
	// }, []);
  //
	// useEffect(() => {
	// 	if (!carcassTypesLoaded) {
	// 		trailService
	// 			.getTrailTypes()
	// 			.then((response) => {
	// 				setCarcassTypes(response);
	// 				setCarcassTypesLoaded(true);
	// 			})
	// 			.catch((errorMsg) => {
	// 				notification.error({
	// 					message: "Получение списка типов кузовов",
	// 					description: errorMsg,
	// 				});
	// 			});
	// 	}
	// }, [])
  //
  //
	// const [fieldsErrors, setFieldsErrors] = useState([]);
  //
	// const [shipperCompanyId, setShipperCompanyId] = useState(null);
	// const [distributionCenterId, setDistributionCenterId] = useState(null);
	// const [countryDeparture, setCountryDeparture] = useState(null);
	// const [addressWarehouse, setAddressWarehouse] = useState(null);
	// const [coordinateLoadLat, setCoordinateLoadLat] = useState('');
	// const [coordinateLoadLon, setCoordinateLoadLon] = useState('');
	// const [number, setNumber] = useState(null);
	// const [weight, setWeight] = useState('');
	// const [volume, setVolume] = useState('');
	// const [packageType, setPackageType] = useState(null);
	// const [ndsPrice, setNdsPrice] = useState(null);
	// const [noNdsPrice, setNoNdsPrice] = useState(null);
	// const [cashPrice, setCashPrice] = useState(null);
	// const [loadingType, setLoadingType] = useState(null);
	// const [dateShipment, setDateShipment] = useState(null);
	// const [comments, setComments] = useState('');
	// const [partialCargo, setPartialCargo] = useState(false);
	// const [assessedValue, setAssessedValue] = useState('');
	// const [allowedCarcassTypes, setAllowedCarcassTypes] = useState([]);
	// const [pointsUnload, setPointsUnload] = useState([new PointUnload()]);
	// const [maxPoints, setMaxPoints] = useState(2);
  //
	// const updatePointsUnload = (key, value, i) => {
  //
	// 	const newPointsUnload = [...pointsUnload];
	// 	newPointsUnload[i][key] = value;
	// 	setPointsUnload(newPointsUnload);
  //
	// };
  //
	// const addPointUnload = () => {
	// 	if (maxPoints <= 20) {
	// 		const newPointsUnload = [...pointsUnload];
	// 		newPointsUnload.push(new PointUnload());
	// 		setPointsUnload(newPointsUnload);
	// 		setMaxPoints(parseInt(maxPoints) + 1);
	// 	}
	// };
  //
  //
	// const removePointUnload = (i) => {
	// 	if (i != 0) {
	// 		const newPointsUnload = [...pointsUnload];
	// 		newPointsUnload.splice(i, 1);
	// 		setPointsUnload(newPointsUnload);
	// 		setMaxPoints(maxPoints - 1);
	// 	}
	// };
  //
  //
	// const getFieldErrors = (nameField) => {
  //
	// 	return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined")
	// 		? fieldsErrors[nameField][0]
	// 		: null;
  //
	// };
  //
	// const getPointErrors = (nameField, pointKey) => {
	// 	return (fieldsErrors && typeof fieldsErrors[pointKey] !== "undefined" && typeof fieldsErrors[pointKey][nameField] !== "undefined")
	// 		? fieldsErrors[pointKey][nameField][0]
	// 		: null;
	// };
  //
	// const saveDelivery = () => {
  //
	// 	setLoading(true);
  //
	// 	deliveriesService.createDelivery(
	// 		{
	// 			ShipperCompanyId: shipperCompanyId,
	// 			DistributionCenterId: distributionCenterId,
	// 			CountryDeparture: countryDeparture ? countyList.find(element => {
	// 				return element.Id === countryDeparture;
	// 			}).Name : '',
	// 			AddressWarehouse: addressWarehouse,
	// 			CoordinateLoadLat: coordinateLoadLat,
	// 			CoordinateLoadLon: coordinateLoadLon,
	// 			Number: number,
	// 			NumberDelivery: number,
	// 			Weight: weight,
	// 			Volume: volume,
	// 			NdsPrice: ndsPrice,
	// 			NoNdsPrice: noNdsPrice,
	// 			CashPrice: cashPrice,
	// 			LoadingType: loadingType,
	// 			DateShipment: dateShipment,
	// 			Comments: comments,
	// 			PointsUnload: pointsUnload,
	// 			PackageType: packageType,
	// 			AssessedValue: assessedValue,
	// 			AllowedCarcassTypes: allowedCarcassTypes,
	// 		}
	// 	)
	// 		.then(response => {
	// 			setLoading(false);
	// 			if (response.code === 400) {
	// 				notification.error({
	// 					message: "Создание рейса",
	// 					description: response.message,
	// 				});
	// 				setFieldsErrors(response.errors);
	// 			} else {
	// 				notification.success({
	// 					message: "Создание рейса",
	// 					description: response.message,
	// 				});
	// 				setIsShow(false);
	// 				setTimeout(document.location.reload(), 2000);
	// 			}
  //
	// 		})
	// 		.catch(errMsg => {
	// 			setLoading(false);
  //
	// 			notification.error({
	// 				message: "Создание рейса",
	// 				description: errMsg,
	// 			});
	// 		})
	// 		;
  //
	// };
  //
	// let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', borderColor: 'transparent', boxShadow: 'none' };
  //
	// const renderPointsUnload = () => {
  //
	// 	return pointsUnload.map((point, i) => (
	// 		<div style={{ border: '1px solid rgba(0, 0, 0, 0.1)', margin: '10px', padding: '15px' }} key={i}>
	// 			<Row gutter={[16, 24]} key={i + 1} >
	// 				<Col lg={12} md={24} sm={24} sx={24} style={{ float: 'left' }}>
	// 					<span>Точка разгрузки № {i + 1}</span>
	// 				</Col>
	// 				<Col lg={12} md={24} sm={24} sx={24}>
	// 					<Button type="danger" icon="close" onClick={() => removePointUnload(i)} style={{ ...bigButtonStyle, backgroundColor: 'red', color: 'white', float: 'right', width: '50px' }} />
	// 				</Col>
  //
	// 				<Col lg={12} md={24} sm={24} sx={24}>
	// 					<SelectField
	// 						title="Страна"
	// 						name="country"
	// 						selectedValue={point.countryId}
	// 						values={countyList}
	// 						keyNum={i}
	// 						onChange={(value, option) => {
	// 							updatePointsUnload('countryId', value, i);
	// 							updatePointsUnload('country', option.props.children, i);
	// 						}}
	// 						getErrors={getPointErrors}
	// 						style={{ width: "100%" }}
	// 					/>
	// 				</Col>
  //
	// 				<Col lg={12} md={24} sm={24} sx={24}>
	// 					<FieldBlock keyNum={i} name="date" title="Дата разгрузки" getErrors={getPointErrors}>
	// 						<DatePicker
	// 							style={{ width: "100%" }}
	// 							format="DD.MM.YYYY"
	// 							size="large"
	// 							name="date"
	// 							locale={localeDatePicker}
	// 							allowClear={false}
	// 							onChange={value => updatePointsUnload('date', value, i)}
	// 						/>
	// 					</FieldBlock>
	// 				</Col>
  //
	// 				<Col lg={24} md={24} sm={24} sx={24}>
	// 					<InputField
	// 						name="address" label="Точный адрес разгрузки"
	// 						value={point.address} setValue={value => updatePointsUnload('address', value, i)}
	// 						keyNum={i}
	// 						getFieldErrors={getPointErrors}
	// 					/>
	// 				</Col>
  //
	// 				{/* <Col lg={12} md={24} sm={24} sx={24}>
  //         <NumberInputField
  //           name="lat" label="Широта"
  //           value={point.lat} setValue={value => updatePointsUnload('lat', value, i)}
  //           keyNum={i}
  //           getFieldErrors={getPointErrors}
  //         />
  //       </Col>
  //
  //       <Col lg={12} md={24} sm={24} sx={24}>
  //         <NumberInputField
  //           name="lon" label="Долгота"
  //           value={point.lon} setValue={value => updatePointsUnload('lon', value, i)}
  //           keyNum={i}
  //           getFieldErrors={getPointErrors}
  //         />
  //     </Col>*/}
	// 			</Row >
	// 		</div >
	// 	));
  //
	// };
  //
	// const renderPointsAddButton = () => {
	// 	if (maxPoints <= 20) {
	// 		return (<Row gutter={[16, 24]} style={{ marginBottom: '60px' }}>
	// 			<Col lg={24} md={24} sm={24} sx={24} style={{ textAlign: 'center' }}>
	// 				<Button type="primary" size="small" htmlType="button" onClick={addPointUnload} style={{ ...bigButtonStyle }}>Добавить точку</Button>
	// 			</Col>
	// 		</Row>);
	// 	} else {
	// 		return null;
	// 	}
	// }

	return (
		<Modal
			title={tenderToDelivery ? "Преобразование Аукциона в рейс" : "Заполнение заявки"}
			cancelText="Закрыть"
			className="modal-window"
			align={null}
			visible={isShow}
			onCancel={() => {
				setIsShow(false);
			}}
			footer={[]}
		>
			<CreateDelivery
				onCreate={() => {setIsShow(false)}}
				deliveryOrigId={deliveryId}
				tenderToDelivery={tenderToDelivery}
		/>
		</Modal>
	);

}
