import React, {useContext, useEffect, useState} from "react";
import {
  FullDate,
  CommentsBlock,
  FullPlacePoints,
  LogistName,
  LogistPhone,
  ShortPlaceUnload,
  LogistInfo, CarrierInfo, Directions
} from "../tools/delivery-tools";
import {Scrollbars} from 'react-custom-scrollbars';
import PriceElement from "../price/PriceElement";
import {GuestHeaderButton} from "../tools/guest-header-buttons";
import {CarrierHeaderButton} from "../tools/carrier-header-buttons";
import {DeliveryOrderInfo} from "../tools/delivery-order-info";
import {LogistShortButton} from "../tools/logist-short-button";
import {LogistFullButton} from "../tools/logist-full-button";
import {Select} from "antd";
import {Link} from "react-router-dom";
import ReturnDeliveries from "../pages/deliveries/carrier/return-deliveries";
import {userServices} from "../../services";
import {LOGIST_LEGAL_ENTITY_APPROVE, VIEW_FREE_DELIVERIES_LOGIST} from "../../helpers/user_permission.helper";
import { DELIVERY_DEFAULT_TYPE } from "../../helpers/delivery.helper";
import { TenderInfo } from "../tender/TenderInfo";


export default function DeliveryItemContent({
                                              delivery,
                                              setLoadingDelivItem,
                                              nameRegionLastPointUnload,
                                              regionLoad,
                                              contentVisible,
                                              setStopListUpdate,
                                              setNeedUpdate,
                                              setContentVisible,
                                              wasOpenedBeforeUpdate,
                                              permissions,
                                              setWasOpenedBeforeUpdate,
                                              ...props
                                            }) {
  useEffect(() => {
    if (wasOpenedBeforeUpdate && wasOpenedBeforeUpdate[delivery.Id]) {
      setContentVisible(false);
    }
  }, [wasOpenedBeforeUpdate]);

  const toggleContentVisible = (e) => {
    e.preventDefault();
    if (wasOpenedBeforeUpdate) {
      setWasOpenedBeforeUpdate({...wasOpenedBeforeUpdate, [delivery.Id]: contentVisible});
    }
    setContentVisible(!contentVisible);
  };

  const wasOpened = wasOpenedBeforeUpdate ? wasOpenedBeforeUpdate[delivery.Id] : false;

  const {Option} = Select;

  const isHaveReturnDeliveries = Boolean(delivery.ReturnDeliveries) ? Boolean(delivery.ReturnDeliveries.length) : false;

  const isUserStaff = !!userServices.getUser().info.isStaff;

  return (
    <>
      <div className={`item-view  ${isHaveReturnDeliveries ? `item-view--with-select` : ``} non-bot-bord clrfx`}>
        <div className="col s1">
          <div className="top-view">
            <div className="citi-info">
              <div className="citi start" title={regionLoad}>
                <span className="citi-name">{regionLoad}</span>
                <span className="info">{delivery.NumberDelivery}</span>
              </div>
              <div className="citi-list">
                <div className="inner-ls">
                  <Scrollbars>
                    <ul>
                      <ShortPlaceUnload delivery={delivery}/>
                    </ul>
                  </Scrollbars>
                </div>
              </div>
              <div title={nameRegionLastPointUnload} className="citi end">{nameRegionLastPointUnload}</div>
            </div>
            {Boolean(isHaveReturnDeliveries) &&
              <div className="item-select">
                <Select
                  size="default"
                  style={{ width: "100%" }}
                  disabled={delivery.Order !== null}
                  placeholder={`Обратные рейсы (${delivery.ReturnDeliveries.length})`}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{background: "#FFFFFF",
                    overflow: "hidden",
                    border: "1px solid #1E7D67",
                    boxShadow: "0px 4px 10px rgba(130, 137, 147, 0.2)",
                    borderRadius: "11px",
                    padding: "10px 3px 10px 10px",
                    height: "142px",
                    width: "308px"}}
                  dropdownClassName="item-select-dropdown"
                >
                  {delivery.ReturnDeliveries.map((item, key)=> {
                    return <Option value={item.Id} className="item-select-option" key={`${item.Id} - ${key}`}>
                      <span className="date">{item.DateShipment}</span>
                      <span>{item.CityLoad}</span>
                      <span>{item.Price}</span>
                    </Option>
                    })
                  }
                </Select>
                <div>
                  <Link className="action-button"
                        disabled={delivery.Order !== null}
                        to={`/deliveries/return-deliveries/${delivery.Id}`}>
                    <span className="speedometer">Оформить 2 рейса</span>
                  </Link>
                </div>
              </div> }
          </div>
          <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
            <div className="ens">
              <div className="info-line">
                <div className="info-line--title">Номер рейса</div>
                <div className="info-line--desc">{delivery.NumberDelivery}</div>
              </div>
              {isUserStaff &&
                  <div className="info-line">
                    <div className="info-line--title">Дата создания рейса</div>
                    <div className="info-line--desc"><FullDate date={delivery.DateCreated}/></div>
                  </div>
              }
              <div className="info-line">
                <div className="info-line--title">Юр. лицо</div>
                <div className="info-line--desc">{delivery.CustomerCompany}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col s2">
          <div className="top-view">
            <div className="col-pos-top">
              <div className="informer-item">
                {delivery.DateShipment &&
                <>
                  <div className="h-informer">Дата отгрузки</div>
                  <div className="b-informer">
                    <div className="date red"><FullDate date={delivery.DateShipment}/></div>
                  </div>
                </>
                }
                {delivery.Tender &&
                <TenderInfo.common delivery={delivery}/>
                }
              </div>
            </div>
            <div className="col-pos-bot ">
              <div className="double-bot clrfx">
                <div className="informer-item">
                  <div className="h-informer">Объем</div>
                  <div className="b-informer">
                    <div className="inf">{delivery.Volume} м<sup>3</sup></div>
                  </div>
                </div>
                <div className="informer-item">
                  <div className="h-informer">Вес</div>
                  <div className="b-informer">
                    <div className="inf">{(delivery.Weight / 1000).toFixed(2)} т.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
            <div className="ens">
              <div className="info-line">
                <div className="info-line--title">Загрузка</div>
                <div className="info-line--desc">{delivery.PlaceLoad.Address}</div>
                <Directions images={delivery.PlaceLoad.Images}/>
                <FullPlacePoints delivery={delivery} type={'load'}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col s3">
          <div className="top-view">

            <div className="col-pos-top">
              <div className="triple-bot clrfx">
                <div className="informer-item">
                  <div className="h-informer">Тип груза</div>
                  <div className="b-informer">
                    <div className="inf">{delivery.CargoType}</div>
                  </div>
                </div>
                <div className="informer-item">
                  <div className="h-informer">Режим</div>
                  <div className="b-informer">
                    <div className="inf">{delivery.CargoMode}</div>
                  </div>
                </div>
                <div className="informer-item">
                  <div className="h-informer">Расстояние</div>
                  <div className="b-informer">
                    <div
                      className="inf">{delivery.DeliveryDistance > 0 ? delivery.DeliveryDistance + " км." : "-"}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-pos-bot ">
              <div className="triple-bot clrfx">
                <div className="informer-item">
                  <div className="h-informer">Упаковка</div>
                  <div className="b-informer">
                    <div className="inf">{delivery.PackageType}</div>
                  </div>
                </div>
                <div className="informer-item">
                  <div className="h-informer">Догруз</div>
                  <div className="b-informer">
                    <div className="inf">{delivery.PartialCargo ? 'Да' : 'Нет'}</div>
                  </div>
                </div>
                <div className="informer-item">
                  <div className="h-informer">Загрузка</div>
                  <div className="b-informer">
                    <div className="inf">{delivery.LoadingType}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
            <div className="ens">
              <div className="info-line">
                <div className="info-line--title">Выгрузка</div>
                <FullPlacePoints delivery={delivery} type={'unload'}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col s4">
          <div className="top-view">
            <a href="#" onClick={toggleContentVisible}
               className={`view-other-info ${contentVisible ? "open" : ""}`}> </a>

            <PriceElement delivery={delivery} permissions={permissions}/>
            {delivery.Tender &&
              <TenderInfo.price delivery={delivery}/>
            }
            <DeliveryOrderInfo delivery={delivery}/>
            <GuestHeaderButton  delivery={delivery} setStopListUpdate={setStopListUpdate}/>

            <CarrierHeaderButton
              delivery={delivery}
              permissions={permissions}
              setNeedUpdate={setNeedUpdate}
              setStopListUpdate={setStopListUpdate}
              setLoadingDelivItem={setLoadingDelivItem}
            />
            <LogistShortButton
              delivery={delivery}
              permissions={permissions}
              setNeedUpdate={setNeedUpdate}
              setStopListUpdate={setStopListUpdate}
              setLoadingDelivItem={setLoadingDelivItem}
            />
          </div>
          <div className={`bot-view ${contentVisible ? "full-view" : ""}`}>
            <LogistInfo delivery={delivery}/>
            <CarrierInfo delivery={delivery}/>

            <LogistFullButton
              delivery={delivery}
              permissions={permissions}
              setNeedUpdate={setNeedUpdate}
              setStopListUpdate={setStopListUpdate}
              setLoadingDelivItem={setLoadingDelivItem}
            />

            <div className="ens">
              <CommentsBlock comments={delivery.Comments}/>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="indicator indicator__red"/>*/}
    </>
  );
}
