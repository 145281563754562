export const DELIVERY_CARRIAGE_TYPE = 'carriage';

export const DELIVERY_CONTAINER_TYPE = 'container';

export const DELIVERY_DEFAULT_TYPE = 'delivery';

export const DELIVERY_AUCTION_PRICE_TYPE = 'auction_price';

export const DELIVERY_AUCTION_DATE_TYPE = 'auction_date';

export const REDUCTION_TYPE = 'reduction';

export const deliveryTypes = {
    delivery: 'Спотовый рейс',
    auction_date: 'Аукцион без указания даты отгрузки',
    auction_price: 'Аукцион без указания начальной цены (запрос ставки)',
    reduction: 'Редукцион (торги на понижение от начальной цены)'
}

export const offerTitle = {
    new : 'на рассмотрении',
    agreed : 'принят',
    refused : 'отменён',
    irrelevant : 'неактуальный',
}