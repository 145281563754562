import React from "react";
import DividerBlock from "../../../../divider-block";
import FieldBlock from '../../../../field-block';
import { DatePicker, TimePicker } from "antd";
import { Col, Row } from "antd";
import localeDatePicker from 'antd/lib/date-picker/locale/ru_RU';
import moment from "moment";

const { RangePicker } = DatePicker;

export default function AuctionDate({
  tenderTimeStart,
  tenderTimeEnd,
  setLoadStart,
  setLoadEnd,
  setTenderStart,
  setTenderEnd,
  setTenderTimeStart,
  setTenderTimeEnd,
  getFieldErrors,
  dividerStyle}
) {
    return (
      <>
      <span className="divider-label">Параметры аукциона</span>
      <DividerBlock style={dividerStyle} />
      <Row gutter={[16, 24]} >
        <Col lg={12} md={24} sm={24} sx={24}>
          <FieldBlock  name="tenderStart" title="Период проведения аукциона (МСК)" getErrors={getFieldErrors}>
            <RangePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              locale={localeDatePicker}
              onChange={(date) => {
                  setTenderStart(date[0]);
                  setTenderEnd(date[1]);
              }}
            />
            <TimePicker 
              defaultValue={tenderTimeStart}
              placeholder="Время начала"
              format="HH:mm"
              onChange={(time, timeString) => {setTenderTimeStart(time)}}
              style={{ width: "50%" }} 
            />
            <TimePicker 
              placeholder="Время окончания"
              defaultValue={tenderTimeEnd}
              format="HH:mm"
              onChange={(time, timeString) => {setTenderTimeEnd(time)}}
              style={{ width: "50%" }} 
            />
          </FieldBlock>
        </Col>
        <Col lg={12} md={24} sm={24} sx={24}>
        <FieldBlock  name="loadStart" title="Диапазон возможных дат загрузки" getErrors={getFieldErrors}>
          <RangePicker
            style={{ width: "100%" }}
            format="YYYY-MM-DD"
            locale={localeDatePicker}
            onChange={(date) => {
              setLoadStart(date[0]);
              setLoadEnd(date[1]);
            }}
          />
        </FieldBlock>
        </Col>
      </Row>
      <Row gutter={[16, 24]} >
      </Row>
      </>
    );
}