import React, {useState} from 'react';
import {Button, Col, Modal, notification, Row, Spin} from "antd";
import '../modals.css';
import {Redirect} from "react-router-dom";

export default function RedirectModal({isShow, setIsShow, text, route, title = 'Уведомление'}) {

    const [loading, setLoading] = useState(false);
    const [redirectStatus, setRedirectStatus] = useState(false);

    let bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', borderColor: "#1CAA53" };

    const textStyle = {
        textAlign: 'center',
        color: 'black'
    };


    const redirect = () => {
        setRedirectStatus(true);
    };

    if (redirectStatus) {
        return (<Redirect to={route} push={true} />)
    }

    return (
        <Modal
            title={<span className="ant-modal-title">{title}</span>}
            cancelText="Закрыть"
            className="modal-window"
            style={{maxWidth: '450px'}}
            align={null}
            visible={isShow}
            onCancel={() => {
                setIsShow(false);
            }}
            footer={[
                <Button htmlType="submit" style={bigButtonStyle} key="submit" type="primary" onClick={() => {redirect()}}>Перейти</Button>,
            ]}
        >
            <Spin spinning={loading} tip="Загрузка...">
                <Row className="add-padding-10 -centered">
                    <h2 style={textStyle}>{text}</h2>
                </Row>
            </Spin>
        </Modal>
    );
}